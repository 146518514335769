import { Route } from 'type-route';

import { groups } from 'app/router';

import Sidebar from '../sidebar';

import { ViewContainer, ContentContainer } from './styles';

type Props = {
    children: React.ReactNode;
    size?: 'medium' | 'large' | 'full';
    route: Route<typeof groups.leagueFull>;
};

const View = ({ children, size, route }: Props) => {
    return (
        <ViewContainer data-testid="view">
            <Sidebar route={route} />
            <ContentContainer size={size}>
                <div className="inner-container" data-testid="view-inner-container">
                    {children}
                </div>
            </ContentContainer>
        </ViewContainer>
    );
};

export default View;
