import styled from '@emotion/styled';

export const Label = styled.div`
    font-weight: 900;
    font-size: 0.625rem;
    margin-right: 0.5rem;
    white-space: nowrap;
`;

export const DateSelectorContainer = styled.div<{
    placeholderWidth?: number;
}>`
    position: relative;
    cursor: pointer;
    max-height: 18rem;
    overflow-y: auto;
    color: var(--color-text);
    background-color: var(--color-background);
    border-radius: 0.2rem;
    border: 0.0625rem solid var(--color-dropdown-border);
    padding: 1rem;
    font-size: 0.625rem;
    ${(props) => props?.placeholderWidth && `min-width: ${props.placeholderWidth / 16}rem;`}

    & > .tabs {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
`;

export const RangePicker = styled.div`
    display: flex;

    & > .separator {
        height: 0.0625rem;
        width: 0.6875rem;
        background-color: var(--color-text);
        align-self: flex-end;
        margin-bottom: 1.7rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
`;

export const SingleDatePicker = styled.div`
    display: flex;
`;

export const RangePickerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & > .label {
        font-weight: 900;
        font-size: 0.625rem;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 1rem;
    & > button {
        width: 100%;
    }
`;

export const ErrorMessage = styled.div`
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: var(--red500);
    font-size: 0.625rem;
`;
