import { useRef } from 'react';

import { ExternalLink } from 'api/types/bff';
import { IconDecorated } from 'components/icons';
import Loading from 'components/loading';
import Popover from 'components/popover';

import Tooltip from '../primitives/tooltip';

import { ExternalLinksContainer, ExternalLinksPlaceholder, PopoverContainer } from './styles';

type Props = {
    linksInfo: ExternalLink[];
    isLoading?: boolean;
};

const ExternalLinks = ({ linksInfo, isLoading }: Props) => {
    const placeholderRef = useRef<HTMLDivElement>(null!);
    const placeholder = (
        <Tooltip title="See the external links related to this flag">
            <ExternalLinksPlaceholder ref={placeholderRef} data-testid="external-links">
                <IconDecorated sizeRem={1.25} name="Language" />
            </ExternalLinksPlaceholder>
        </Tooltip>
    );
    const anchorVerticalOrigin = placeholderRef.current
        ? placeholderRef.current.offsetHeight + 2
        : 'bottom';

    return (
        <PopoverContainer>
            <Popover
                placeholder={placeholder}
                anchorOrigin={{ horizontal: 'left', vertical: anchorVerticalOrigin }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                {() => {
                    return (
                        <ExternalLinksContainer>
                            {isLoading && (
                                <div className="loading">
                                    <Loading />
                                </div>
                            )}
                            {!isLoading &&
                                linksInfo.length > 0 &&
                                linksInfo.map(({ title, url }) => (
                                    <a key={title} target="_blank" href={url} rel="noreferrer">
                                        {title}
                                    </a>
                                ))}
                            {!isLoading && linksInfo.length < 1 && (
                                <div className="label">No links</div>
                            )}
                        </ExternalLinksContainer>
                    );
                }}
            </Popover>
        </PopoverContainer>
    );
};

export default ExternalLinks;
