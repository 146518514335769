import { toUtf8 } from '@aws-sdk/util-utf8-browser';
import { mqtt5 } from 'aws-iot-device-sdk-v2';
import * as React from 'react';
import { useQueryClient } from 'react-query';

import { messageProcessorsRegistry } from './message-processors/registry';
import { MessageProcessor } from './message-processors/types';

export function useProcessWebsocketMessage() {
    const queryClient = useQueryClient();

    const process = React.useCallback(
        (eventData: mqtt5.MessageReceivedEvent) => {
            const payload = JSON.parse(toUtf8(eventData.message.payload as Buffer));

            const regex = /\/service:(?<service>[\w-]+)/gm;

            const result = regex.exec(eventData.message.topicName);

            if (
                !result ||
                !result?.groups?.service ||
                !messageProcessorsRegistry.has(result.groups.service)
            ) {
                console.warn(
                    'useProcessWebsocketMessage Unhandled topic: %s',
                    eventData.message.topicName,
                );

                return;
            }

            const processor = messageProcessorsRegistry.get(
                result.groups.service,
            ) as MessageProcessor;

            processor(queryClient, payload);
        },
        [queryClient],
    );

    return {
        process,
    };
}
