import { mqtt5 } from 'aws-iot-device-sdk-v2';
import dayjs from 'dayjs';
import * as React from 'react';

import { SystemEvents } from 'api/types/system-events';
import { useSubscribeTopicLazy } from 'app/useSubscribeTopic';
import Chip from 'components/chip';
import { sourcesDetails } from 'constants/sources-details';
import { SourceId } from 'types';

import {
    GroupedTaskContainer,
    ProgressStatusLabelContainer,
    TaskAccordionDetails,
    TaskAccordionSummary,
} from '../../modules/admin/tasks/views/styles';
import { Accordion, AccordionDetails, AccordionSummary } from '../accordion';
import { IconDecorated } from '../icons';
import Loading from '../loading';
import Text from '../primitives/text';

import TaskExpandedContent from './components/task-expanded-content';
import { useGroupByTaskId } from './hooks/useGroupByTaskId';
import useGroupTasks, { GroupedTask, Task } from './hooks/useGroupTasks';
import { GroupTaskWrapper, TaskContainer } from './styles';

type Props = {
    onOpenTask?: (gt: GroupedTask) => void;
    onCloseTask?: (gt: GroupedTask) => void;
    onViewTaskDetails: (t: Task) => void;
    systemEvents: SystemEvents.SystemEvent[];
    loading?: boolean;
    expanded?: boolean;
    pk?: string;
};

const Tasks = (props: Props) => {
    const groupedTasks = useGroupTasks(props.systemEvents);
    const { subscribeTopic, unsubscribeTopic } = useSubscribeTopicLazy();

    const createTopic = React.useCallback((gt: GroupedTask) => {
        return `/${gt.pk.replaceAll('#', '-')}`;
    }, []);

    const extractMetadata = React.useCallback((gt: GroupedTask) => {
        const regex = /(?<league>\w+)#(?<seasonYear>\d+)#(?<seasonType>\w+)/gm;
        const match = regex.exec(gt.pk);

        if (!match) {
            return null;
        }

        const { league, seasonYear, seasonType } = match.groups as {
            league: string;
            seasonYear: string;
            seasonType: string;
        };

        return { league, seasonYear, seasonType };
    }, []);

    const handleOpenTask = React.useCallback(
        (gt: GroupedTask) => {
            if (props.onOpenTask) {
                props.onOpenTask(gt);
            }

            const metadata = extractMetadata(gt);

            if (!metadata) {
                return;
            }

            subscribeTopic('system-events', createTopic(gt), mqtt5.QoS.AtLeastOnce);
        },
        [createTopic, extractMetadata, props, subscribeTopic],
    );

    const handleCloseTask = React.useCallback(
        (gt: GroupedTask) => {
            if (props.onCloseTask) {
                props.onCloseTask(gt);
            }

            const metadata = extractMetadata(gt);

            if (!metadata) {
                return;
            }

            unsubscribeTopic('system-events', createTopic(gt));
        },
        [createTopic, extractMetadata, props, unsubscribeTopic],
    );

    const handleChange = React.useCallback(
        (gt: GroupedTask) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            isExpanded ? handleOpenTask(gt) : handleCloseTask(gt);
        },
        [handleCloseTask, handleOpenTask],
    );

    // this useeffect is responsible for subscribing to the topics when on a flag level view when tasks are expanded by default
    React.useEffect(() => {
        if (props.expanded === undefined) {
            return;
        }

        const topics = groupedTasks.map((gt) => {
            const topicTuple: [string, string] = ['system-events', createTopic(gt)];

            return topicTuple;
        });

        // this is needed because when there are no events and we still want to use the sockets we need to use pk as a part of topic
        props.pk && topics.push(['system-events', '/' + props.pk.replaceAll('#', '-')]);

        topics.forEach(
            (topic) => topic !== null && subscribeTopic(...topic, mqtt5.QoS.AtLeastOnce),
        );

        return () => {
            topics.forEach((topic) => topic !== null && unsubscribeTopic(...topic));
        };
    }, [
        createTopic,
        extractMetadata,
        groupedTasks,
        props.expanded,
        props.pk,
        subscribeTopic,
        unsubscribeTopic,
    ]);

    const groupByTaskId = useGroupByTaskId();

    return (
        <TaskContainer expanded={props.expanded}>
            {props.loading && (
                <div className="loading-container">
                    <Loading mt={2} />
                </div>
            )}
            {!props.loading &&
                groupedTasks.map((gt) => {
                    return (
                        <Accordion
                            key={`task-${gt.pk}`}
                            onChange={handleChange(gt)}
                            expanded={props.expanded}
                        >
                            <AccordionSummary
                                expandIcon={
                                    props.expanded ? null : (
                                        <IconDecorated
                                            paletteColor="blue400"
                                            sizeRem={2}
                                            name="ArrowDropDown"
                                        />
                                    )
                                }
                            >
                                <GroupTaskWrapper>
                                    <Chip variant="successPrimary">{gt.context.toUpperCase()}</Chip>
                                    <Chip variant="warningPrimary">{gt.pk}</Chip>
                                    <Text>
                                        {dayjs
                                            .unix(Number(gt.timestamp))
                                            .format('YYYY-MM-DD HH:mm:ss Z')}
                                    </Text>
                                </GroupTaskWrapper>
                            </AccordionSummary>
                            <AccordionDetails data-testid={`task-details-${gt.pk}`}>
                                <GroupedTaskContainer>
                                    {groupByTaskId(gt).map((taskGroup) => {
                                        const source = taskGroup.lastTask.sk.split('_').pop();
                                        const isCompleted =
                                            taskGroup.tasks.find(
                                                (t) => t.detailType === 'task-completed',
                                            ) !== undefined;

                                        return (
                                            <Accordion key={`task-${taskGroup.taskId}`}>
                                                <AccordionSummary
                                                    expandIcon={
                                                        <IconDecorated
                                                            paletteColor="blue400"
                                                            sizeRem={2}
                                                            name="ArrowDropDown"
                                                        />
                                                    }
                                                >
                                                    <TaskAccordionSummary>
                                                        <Text>
                                                            <b>Task Id:</b> {taskGroup.taskId}
                                                        </Text>
                                                        <Text>
                                                            <b>Source:</b>{' '}
                                                            {sourcesDetails[
                                                                taskGroup.lastTask
                                                                    .sourceId as SourceId
                                                            ]?.label ?? taskGroup.lastTask.sourceId}
                                                        </Text>
                                                        <Text align="justify">
                                                            <b>Last event date: </b>
                                                            {dayjs
                                                                .unix(
                                                                    Number(
                                                                        taskGroup.lastTask
                                                                            .timestamp,
                                                                    ),
                                                                )
                                                                .format('YYYY-MM-DD HH:mm:ss')}
                                                        </Text>
                                                        <Text>
                                                            <b>Last Service: </b>
                                                            {source?.replace('smm.', '')}
                                                        </Text>
                                                        <Text>
                                                            <b>Events:</b> {taskGroup.tasks.length}
                                                        </Text>
                                                        <ProgressStatusLabelContainer>
                                                            <Chip
                                                                variant={
                                                                    isCompleted
                                                                        ? 'successPrimary'
                                                                        : 'warningPrimary'
                                                                }
                                                            >
                                                                {isCompleted
                                                                    ? 'Completed'
                                                                    : 'In Progress'}
                                                            </Chip>
                                                        </ProgressStatusLabelContainer>
                                                    </TaskAccordionSummary>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TaskAccordionDetails>
                                                        {taskGroup.tasks.map((task) => (
                                                            <TaskExpandedContent
                                                                key={`task_${task.taskId}_${task.pk}_${task.sk}`}
                                                                task={task}
                                                                onViewTaskDetails={
                                                                    props.onViewTaskDetails
                                                                }
                                                            />
                                                        ))}
                                                    </TaskAccordionDetails>
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}
                                </GroupedTaskContainer>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
        </TaskContainer>
    );
};

export default Tasks;
