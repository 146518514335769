import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import * as React from 'react';

import { DiscrepancyType } from 'api/types/bff';
import Checkbox from 'components/primitives/checkbox';
import Tooltip from 'components/primitives/tooltip';

import BulkActions from '../../bulk-actions';
import useBulkActions from '../../bulk-actions/hooks/use-bulk-actions';
import Discrepancy from '../../discrepancy';
import { DiscrepanciesContainer, DiscrepancyRow } from '../boxscore/styles';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

interface DiscrepancyCategorizedProps {
    title: string;
    discrepancies: DiscrepancyType[];
    type: 'player' | 'team';
}

const DiscrepancyCategorized = ({ title, discrepancies, type }: DiscrepancyCategorizedProps) => {
    const getTitle = React.useCallback(
        (discrepancy: DiscrepancyType) => {
            if (type === 'team') {
                return discrepancy.contextTeam?.alias;
            } else if (type === 'player') {
                return discrepancy.contextPlayer?.name ?? discrepancy.contextPlayer?.srId ?? 'N/A';
            }

            return 'N/A';
        },
        [type],
    );

    const { checkedDiscrepancies, handleToggleCheckDiscrepancy, handleToggleAll, isActive } =
        useBulkActions([...discrepancies].map((d) => d.id));

    if (discrepancies.length === 0) {
        return null;
    }

    const isChecked = (id: string) => {
        return checkedDiscrepancies.includes(id);
    };

    return (
        <DiscrepanciesContainer>
            <div className="header">{title}</div>
            <div className="list" data-testid="roster-flag-discrepancy-rows">
                <BulkActions
                    isActive={isActive}
                    checkedDiscrepancyIds={checkedDiscrepancies}
                    onToggleSelect={handleToggleAll}
                />
                {discrepancies.map((discrepancy) => (
                    <DiscrepancyRow
                        key={`d-row-${discrepancy.id}`}
                        onClick={() => handleToggleCheckDiscrepancy(discrepancy.id)}
                    >
                        <Tooltip
                            disableInteractive
                            placement="left"
                            title={`Updated at ${dayjs(discrepancy.updatedAt).format(
                                'MM/DD/YY hh:mm A z',
                            )}`}
                        >
                            <div className="title-wrapper">
                                <div
                                    className="title"
                                    data-testid={`roster-flag-discrepancy-title-${discrepancy.id}`}
                                >
                                    {getTitle(discrepancy)}
                                </div>
                                <Checkbox checked={isChecked(discrepancy.id)} onChange={() => {}} />
                            </div>
                        </Tooltip>
                        <Discrepancy discrepancy={discrepancy} />
                    </DiscrepancyRow>
                ))}
            </div>
        </DiscrepanciesContainer>
    );
};

export default DiscrepancyCategorized;
