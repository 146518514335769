import styled from '@emotion/styled';

export const MappingColumnsContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 3.5rem;

    .mapping-columns-header {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
    }

    .mapping-columns-container {
        display: grid;
        grid-template-columns: 1fr 0.0625rem 1fr;
        column-gap: 2.5rem;
    }

    .mapping-columns-external-droppable {
        height: 100%;
    }

    &:has(.mapping-column-list) {
        .mapping-columns-divider {
            height: calc(100% - 6.8125rem);
            background-color: var(--color-borderline);
            align-self: end;
        }
    }
`;
