import * as React from 'react';

import { GroupedTask, Task } from './useGroupTasks';

export function useGroupByTaskId() {
    return React.useCallback((gt: GroupedTask) => {
        type TaskGroup = Array<{ taskId: string; lastTask: Task; tasks: Array<Task> }>;

        return gt.tasks.reduce<TaskGroup>((acc, task) => {
            const taskId = task.taskId;

            const taskGroup = acc.find((tg) => tg.taskId === taskId);

            if (!taskGroup) {
                acc.push({
                    taskId,
                    lastTask: task,
                    tasks: [task],
                });
            } else {
                const lastTaskInGroup = taskGroup.lastTask;

                if (Number(task.timestamp) > Number(lastTaskInGroup.timestamp)) {
                    taskGroup.lastTask = task;
                }

                taskGroup.tasks.push(task);
                taskGroup.tasks.sort((a, b) => {
                    return Number(a.timestamp) > Number(b.timestamp) ? -1 : 1;
                });
            }

            return acc;
        }, []);
    }, []);
}
