import * as React from 'react';

import { MappingEntity } from 'api/types/mapper';
import Button from 'components/primitives/button';
import { useMappingEntityAttributes } from 'hooks/mapping/useMappingEntityAttributes';

import { AttributesCard, Card } from '../styles';

type Props = {
    entity: MappingEntity;
    isSubmitting?: boolean;
    onCancelClick: () => void;
    onConfirmClick: (id: string) => void;
    onRemapClick: (entity: MappingEntity) => void;
};

export const CardUnmapping = (props: Props) => {
    const attributes = useMappingEntityAttributes(props.entity, '-');

    return (
        <Card>
            <p className="card-title">
                Unmap a <span className="card-title-type">{props.entity.type}</span>
            </p>
            <div className="card-content">
                <AttributesCard variant="unmapping">
                    <p className="attributes-card-title">{props.entity.type} To Be Unmapped</p>
                    <div className="attributes-card-entity-container">
                        <div className="attributes-card-entity">{props.entity.name}</div>
                    </div>
                    <div className="attributes-card-attributes">
                        {Object.entries(attributes).map(([key, value]) => (
                            <React.Fragment key={key}>
                                <span className="attributes-card-label">{key}</span>
                                <span>{value}</span>
                            </React.Fragment>
                        ))}
                    </div>
                </AttributesCard>
            </div>
            <div className="card-actions">
                <Button noBorder onClick={props.onCancelClick}>
                    Cancel
                </Button>
                <Button noBorder onClick={() => props.onRemapClick(props.entity)}>
                    Remap
                </Button>
                <Button
                    disabled={props.isSubmitting}
                    onClick={() => props.onConfirmClick(props.entity.id)}
                    type="submit"
                >
                    {props.isSubmitting ? 'Unmapping...' : 'Confirm unmapping'}
                </Button>
            </div>
        </Card>
    );
};
