import * as React from 'react';

import { MappingStatus, MappingType, TeamMappingsStatsResponse } from 'api/types/mapper';
import { SourceId } from 'types';

const MAPPINGS_KEY_MAP: Record<MappingType, keyof TeamMappingsStatsResponse> = {
    [MappingType.PLAYER]: 'players',
    [MappingType.SCHEDULE]: 'schedules',
};

export function useTeamMappingsStatsAggregated(
    mappingsStats: TeamMappingsStatsResponse | undefined,
    source: SourceId,
    type: MappingType,
) {
    return React.useMemo(
        () => ({
            [MappingStatus.AUTOMATIC]:
                mappingsStats?.[MAPPINGS_KEY_MAP[type]]?.[source]?.autoMapped ?? 0,
            [MappingStatus.MANUAL]:
                mappingsStats?.[MAPPINGS_KEY_MAP[type]]?.[source]?.manuallyMapped ?? 0,
            [MappingStatus.UNMAPPED]:
                (mappingsStats?.[MAPPINGS_KEY_MAP[type]]?.[source]?.autoUnmapped ?? 0) +
                (mappingsStats?.[MAPPINGS_KEY_MAP[type]]?.[source]?.manuallyUnmapped ?? 0),
        }),
        [mappingsStats, source, type],
    );
}
