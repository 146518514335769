import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';

export interface StyledTextProps extends HTMLAttributes<HTMLElement> {
    align?: 'left' | 'center' | 'right' | 'justify';
}

export const StyledText = styled.span<StyledTextProps>`
    font-size: var(--p-300);
    color: var(--color-text);
    text-align: ${(props) => props.align || 'left'};
`;
