import * as React from 'react';

import { TooltipContainer } from './styles';

const Tooltip = ({
    disabled,
    ...props
}: React.ComponentProps<typeof TooltipContainer> & { disabled?: boolean }) => {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen((open) => (disabled ? false : open));
    }, [disabled]);

    return (
        <TooltipContainer
            {...props}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen((open) => (disabled ? open : true))}
        />
    );
};

export default Tooltip;
