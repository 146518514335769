import {
    Data,
    DndContext,
    DragEndEvent,
    PointerSensor,
    pointerWithin,
    useSensor,
} from '@dnd-kit/core';
import * as React from 'react';

import { MappingEntity, MappingStatus, MappingType } from 'api/types/mapper';
import Loading from 'components/loading';
import Modal from 'components/modal';
import SourceSelector from 'components/source-selector';
import Stat from 'components/stat';
import Stats from 'components/stats';
import { useTeamMappings } from 'hooks/mapping/useTeamMappings';
import { useTeamMappingsMutation } from 'hooks/mapping/useTeamMappingsMutation';
import { useTeamMappingsQuery } from 'hooks/mapping/useTeamMappingsQuery';
import { useTeamMappingsStatsAggregated } from 'hooks/mapping/useTeamMappingsStatsAggregated';
import { useTeamMappingsStatsBySource } from 'hooks/mapping/useTeamMappingsStatsBySource';
import { useTeamMappingsStatsQuery } from 'hooks/mapping/useTeamMappingsStatsQuery';
import { LeagueType, SourceId } from 'types';

import { CardMapping } from '../mapping-cards/card-mapping';
import { CardUnmapping } from '../mapping-cards/card-unmapping';
import { MappingColumn } from '../mapping-column';
import { MappingDraggable } from '../mapping-draggable';
import { MappingDroppable } from '../mapping-droppable';
import { MappingItem } from '../mapping-item';

import { MappingColumnsContainer } from './styles';

enum ActionType {
    MAPPING = 'mapping',
    UNMAPPING = 'unmapping',
    REMAPPING = 'remapping',
}

type Action = {
    type: ActionType;
    source: MappingEntity;
    target?: MappingEntity;
};

function getIsOverExternalContainer(activeData?: Data) {
    return activeData?.status !== MappingStatus.UNMAPPED;
}

function getIsOverSportradarContainer(activeData?: Data, overId?: string | number) {
    return activeData?.srId !== overId;
}

type Props = {
    league: LeagueType;
    onSourceChange: (source: SourceId) => void;
    source: SourceId;
    sources: SourceId[];
    teamId: string;
    type: MappingType;
};

export const MappingColumns = (props: Props) => {
    const sensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } });

    const mappingsStatsQuery = useTeamMappingsStatsQuery({
        league: props.league,
        teamId: props.teamId,
    });
    const mappingStatsAggregated = useTeamMappingsStatsAggregated(
        mappingsStatsQuery.data,
        props.source,
        props.type,
    );
    const mappingsStatsBySource = useTeamMappingsStatsBySource(
        mappingsStatsQuery.data,
        props.sources,
        props.type,
    );

    const mappingsMutation = useTeamMappingsMutation({
        league: props.league,
        source: props.source,
        teamId: props.teamId,
        type: props.type,
    });
    const mappingsQuery = useTeamMappingsQuery({
        league: props.league,
        source: props.source,
        teamId: props.teamId,
    });
    const mappings = useTeamMappings(mappingsQuery.data, props.type);

    const [action, setAction] = React.useState<Action>();

    const handleMapClick = React.useCallback(
        (entity: MappingEntity) => setAction({ type: ActionType.MAPPING, source: entity }),
        [setAction],
    );
    const handleRemapClick = React.useCallback(
        (entity: MappingEntity) => setAction({ type: ActionType.REMAPPING, source: entity }),
        [setAction],
    );
    const handleUnmapClick = React.useCallback(
        (entity: MappingEntity) => setAction({ type: ActionType.UNMAPPING, source: entity }),
        [setAction],
    );

    const handleMappingSubmit = React.useCallback(
        (id: string, srId?: string) => mappingsMutation.mutate({ id, srId: srId ?? null }),
        [mappingsMutation],
    );
    const handleMappingCancel = React.useCallback(() => setAction(undefined), [setAction]);

    const handleDragEnd = React.useCallback(
        (event: DragEndEvent) => {
            if (!event.active.data.current) {
                return;
            }

            if (
                event.over?.id === MappingStatus.UNMAPPED &&
                event.active.data.current?.status !== MappingStatus.UNMAPPED
            ) {
                setAction({
                    type: ActionType.UNMAPPING,
                    source: event.active.data.current as MappingEntity,
                });
            }

            if (
                event.over?.data.current?.source === SourceId.SR &&
                event.active.data.current?.srId !== event.over?.id
            ) {
                setAction({
                    type:
                        event.active.data.current.status === MappingStatus.UNMAPPED
                            ? ActionType.MAPPING
                            : ActionType.REMAPPING,
                    source: event.active.data.current as MappingEntity,
                    target: event.over?.data.current as MappingEntity,
                });
            }
        },
        [setAction],
    );

    React.useEffect(() => {
        setAction(undefined);
    }, [mappingsQuery.dataUpdatedAt]);

    return (
        <MappingColumnsContainer>
            <div className="mapping-columns-header">
                <SourceSelector
                    activeSource={props.source}
                    onSourceClick={props.onSourceChange}
                    valuesBySource={mappingsStatsBySource}
                />
                <Stats data-testid="source-mappings-stats">
                    <Stat
                        label="Auto-mapped"
                        value={mappingStatsAggregated[MappingStatus.AUTOMATIC]}
                        iconKey="IgnoredFlag"
                        opacity={0.1}
                        color="black300"
                        paletteColor="black300"
                    />
                    <Stat
                        label="Manually Mapped"
                        value={mappingStatsAggregated[MappingStatus.MANUAL]}
                        iconKey="IgnoredFlag"
                        opacity={0.1}
                        color="black300"
                        paletteColor="black300"
                    />
                    <Stat
                        label="Unmapped"
                        value={mappingStatsAggregated[MappingStatus.UNMAPPED]}
                        iconKey="Flag"
                        opacity={0.05}
                        color="red500"
                        paletteColor="red600"
                    />
                </Stats>
            </div>
            {mappingsQuery.isLoading && <Loading />}
            {!mappingsQuery.isLoading && (
                <div className="mapping-columns-container">
                    <DndContext
                        collisionDetection={pointerWithin}
                        onDragEnd={handleDragEnd}
                        sensors={[sensor]}
                    >
                        <MappingColumn title="External Mappings" entities={mappings.external}>
                            {(entities) => (
                                <MappingDroppable
                                    className="mapping-columns-external-droppable"
                                    id={MappingStatus.UNMAPPED}
                                    getIsOver={getIsOverExternalContainer}
                                >
                                    {entities.map((entity) => (
                                        <MappingDraggable
                                            key={entity.id}
                                            data={entity}
                                            id={entity.id}
                                        >
                                            <MappingItem
                                                entity={entity}
                                                onMapClick={handleMapClick}
                                            />
                                        </MappingDraggable>
                                    ))}
                                </MappingDroppable>
                            )}
                        </MappingColumn>
                        <div className="mapping-columns-divider" />
                        <MappingColumn
                            title="Sportradar Mappings"
                            entities={mappings.internal}
                            hasUnmappedCheckbox
                        >
                            {(entities) =>
                                entities.map((entity) => (
                                    <MappingDroppable
                                        key={entity.id}
                                        data={entity}
                                        id={entity.id}
                                        getIsOver={getIsOverSportradarContainer}
                                    >
                                        <MappingItem
                                            entity={entity}
                                            onMapClick={handleMapClick}
                                            onRemapClick={handleRemapClick}
                                            onUnmapClick={handleUnmapClick}
                                        />
                                    </MappingDroppable>
                                ))
                            }
                        </MappingColumn>
                    </DndContext>
                </div>
            )}
            <Modal
                open={!!action}
                style={{ padding: '1.5rem' }}
                onBackDropClick={handleMappingCancel}
            >
                {(action?.type === ActionType.MAPPING || action?.type === ActionType.REMAPPING) && (
                    <CardMapping
                        dropdownEntities={
                            action.source.source === SourceId.SR
                                ? mappings.external
                                : mappings.internal
                        }
                        dropdownInitialValue={action.target}
                        entity={action.source}
                        isSubmitting={mappingsMutation.isLoading || mappingsQuery.isFetching}
                        onCancelClick={handleMappingCancel}
                        onConfirmClick={handleMappingSubmit}
                        type={action.type}
                    />
                )}
                {action?.type === ActionType.UNMAPPING && (
                    <CardUnmapping
                        entity={action.source}
                        onCancelClick={handleMappingCancel}
                        onConfirmClick={handleMappingSubmit}
                        onRemapClick={handleRemapClick}
                        isSubmitting={mappingsMutation.isLoading || mappingsQuery.isFetching}
                    />
                )}
            </Modal>
        </MappingColumnsContainer>
    );
};
