import iconShield from 'assets/svg/icon-shield.svg';
import { IconDecorated } from 'components/icons';
import Tooltip from 'components/primitives/tooltip';
import useDateSnapshot from 'hooks/useDateSnapshot';

import { FlagContentInternal, VisibleContent } from '../../styles';

import { TeamCrestPlaceholder } from './styles';

interface HeaderInternalProps {
    teamName: string;
    srTeamId: string;
    ignoredCount: number;
    createdCount: number;
    createdAt: string;
    isToggled: boolean;
    handleToggle: () => void;
}

const HeaderInternal = ({
    teamName,
    ignoredCount,
    createdCount,
    createdAt,
    isToggled,
    handleToggle,
}: HeaderInternalProps) => {
    const createDate = useDateSnapshot(createdAt);

    return (
        <Tooltip disableInteractive placement="left" title={`Created at ${createDate}`}>
            <VisibleContent
                visible={isToggled}
                onClick={handleToggle}
                data-testid="flag-seasonal-header-internal"
            >
                {isToggled ? (
                    <IconDecorated sizeRem={1.5} name="KeyboardArrowUp" paletteColor="gray300" />
                ) : (
                    <TeamCrestPlaceholder alt="team-crest" src={iconShield} />
                )}

                <FlagContentInternal>
                    <div className="team-name">{teamName}</div>
                    <div className="stats">
                        <div className="flagged">
                            <IconDecorated sizeRem={0.75} name="Flag" paletteColor="red600" />
                            <span>{createdCount}</span>
                        </div>
                        <div className="ignored">
                            <IconDecorated sizeRem={0.75} name="Texture" paletteColor="black300" />
                            <span>{ignoredCount}</span>
                        </div>
                    </div>
                </FlagContentInternal>
            </VisibleContent>
        </Tooltip>
    );
};

export default HeaderInternal;
