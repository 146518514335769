import * as React from 'react';

import { MappingStatus, MappingType } from 'api/types/mapper';
import { useCurrentSeason } from 'app/useCurrentSeason';
import { useTeamMappingsStatsByType } from 'hooks/mapping/useTeamMappingStatsByType';
import { useTeamMappingsStatsQuery } from 'hooks/mapping/useTeamMappingsStatsQuery';
import { StatsResponse } from 'hooks/useFlagStats/types';
import { useGetFlagStats } from 'hooks/useFlagStats/useGetFlagStats';
import { LeagueType } from 'types';

export interface TeamNavigationStats {
    stats: number;
    roster: number;
    schedule: number;
    mapping: number;
    seasonal: number;
    standings: number;
    polls: number;
    admin: number;
}

export default function useNavigationStats(league: LeagueType, teamSrusId: string) {
    const currentSeason = useCurrentSeason();
    const {
        data: flagStats,
        isLoading: isFlagStatsLoading,
        error: flagStatsError,
    } = useGetFlagStats({ ...currentSeason, contextObjectId: teamSrusId });
    const mappingsStatsQuery = useTeamMappingsStatsQuery({
        league,
        teamId: teamSrusId,
    });
    const mappingsStatsByType = useTeamMappingsStatsByType(mappingsStatsQuery.data);

    return React.useMemo(() => {
        const navigationStats: TeamNavigationStats = {
            stats: 0,
            roster: 0,
            schedule: 0,
            mapping: 0,
            seasonal: 0,
            standings: 0,
            polls: 0,
            admin: 0,
        };

        if (flagStats && !isFlagStatsLoading && !flagStatsError) {
            setFlagsStats(flagStats, navigationStats);
        }

        navigationStats.mapping =
            mappingsStatsByType[MappingType.PLAYER][MappingStatus.UNMAPPED] +
            mappingsStatsByType[MappingType.SCHEDULE][MappingStatus.UNMAPPED];

        return navigationStats;
    }, [flagStats, flagStatsError, isFlagStatsLoading, mappingsStatsByType]);
}

function setFlagsStats(flagStats: StatsResponse, navigationStats: TeamNavigationStats) {
    const flagTypeStats: Record<string, number> = Object.keys(flagStats).reduce<
        Record<string, number>
    >((prev, cur) => {
        return {
            ...prev,
            [cur]: 0,
        };
    }, {});

    for (const flagType of Object.keys(flagStats)) {
        for (const contextType of Object.keys(flagStats[flagType])) {
            for (const source of Object.keys(flagStats[flagType][contextType])) {
                flagTypeStats[flagType] += flagStats[flagType][contextType][source]['CREATED'] ?? 0;
            }
        }
    }

    navigationStats.stats = flagTypeStats['boxscore'] ?? 0;
    navigationStats.roster = flagTypeStats['roster'] ?? 0;
    navigationStats.schedule = flagTypeStats['schedule'] ?? 0;
    navigationStats.seasonal = flagTypeStats['seasonal'] ?? 0;
    navigationStats.standings = flagTypeStats['standings'] ?? 0;
    navigationStats.polls = flagTypeStats['polls'] ?? 0;
}
