import * as React from 'react';

import { useColorSchemeConfig } from '../../../app/theme/useColorScheme';
import { IconDecorated } from '../../icons';

import { CheckboxContainer, CheckboxUnchecked, CheckedWrapper } from './styles';

type CheckboxProps = {
    checked: boolean;
    label?: string;
    onChange: () => void;
};

const Checkbox = ({ checked, label, onChange }: CheckboxProps) => {
    const [colorScheme] = useColorSchemeConfig();

    return (
        <CheckboxContainer onClick={onChange}>
            {checked && (
                <CheckedWrapper>
                    <IconDecorated
                        sizeRem={0.7}
                        name="CheckRounded"
                        paletteColor={colorScheme === 'light' ? 'blue500' : 'blue300'}
                    />
                </CheckedWrapper>
            )}
            {!checked && <CheckboxUnchecked isDark={colorScheme === 'dark'} />}
            {label && <span>{label}</span>}
        </CheckboxContainer>
    );
};

export default Checkbox;
