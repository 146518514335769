import styled from '@emotion/styled';

export const MappingColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    .mapping-column-header {
        display: flex;
        flex-direction: column;
        row-gap: 0.75rem;
    }

    .mapping-column-title {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.9375rem;
    }

    .mapping-column-controls {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        height: 2.125rem;
    }

    .mapping-column-controls-group {
        display: flex;
        flex-direction: row;
        column-gap: 0.25rem;
    }

    .mapping-column-input {
        place-items: center;
        color: var(--color-text);
        min-width: 16rem;
        border-radius: 0.2rem;
        border: 0.0625rem solid var(--color-dropdown-border);
        padding: 0 0.5rem;
        background-color: transparent;
        font-weight: 700;
        font-size: 0.625rem;
        text-transform: uppercase;
    }

    .mapping-column-list {
        flex-grow: 1;
    }
`;
