import * as React from 'react';
import { Route } from 'type-route';

import { routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import Loading from 'components/loading';
import { MappingColumns } from 'components/mapping/mapping-columns';
import View from 'components/view';
import { useChangeParams } from 'hooks/useChangeParams';
import useDateSnapshot from 'hooks/useDateSnapshot';
import { ExternalSourceId, SourceId } from 'types';

import { sourcesDetails } from '../../../../constants/sources-details';
import Navigation from '../components/navigation';
import { NavigationMapping } from '../components/navigation-mapping';
import TeamHeader from '../components/team-header';
import { useGoBackToTeams } from '../hooks/useGobackToTeams';
import useNavigationStats from '../hooks/useNavigationStats';
import { useTeam } from '../hooks/useTeam';

type MappingProps = {
    route: Route<typeof routes.teamsTeamMapping>;
};

const TeamMapping = ({ route }: MappingProps) => {
    const leagueDetails = useLeagueDetails(route.params.league);
    const source = route.params.source ?? leagueDetails.mappingSiteSources[0];
    const sources = leagueDetails.mappingSiteSources.filter((source) =>
        sourcesDetails[source].mappingTypes.includes(route.params.type),
    );

    const navigationStats = useNavigationStats(route.params.league, route.params.id);
    const teamsQuery = useTeam(route.params.id);
    const updateDate = useDateSnapshot();

    const handleGoBackToTeams = useGoBackToTeams(route);
    const handleParamsChange = useChangeParams(route);

    const handleSourceChange = React.useCallback(
        (sourceId: SourceId) => handleParamsChange({ source: sourceId as ExternalSourceId }),
        [handleParamsChange],
    );

    if (teamsQuery.isLoading) {
        return <Loading mt={4} />;
    }

    if (!teamsQuery.data) {
        return <>Not Found</>;
    }

    return (
        <View route={route} size="large">
            <TeamHeader
                team={teamsQuery.data}
                updateDate={updateDate}
                onBackButtonClick={handleGoBackToTeams}
            />
            <Navigation navigationStats={navigationStats} route={route} />
            <NavigationMapping route={route} source={source} />
            <MappingColumns
                league={route.params.league}
                onSourceChange={handleSourceChange}
                source={source}
                sources={sources}
                teamId={route.params.id}
                type={route.params.type}
            />
        </View>
    );
};

export default TeamMapping;
