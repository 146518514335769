import * as React from 'react';

import { Icon, IconKeys } from 'components/icons';

import { ButtonContainer } from './styles';
type Props = {
    iconName: IconKeys;
    content: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    variant: 'default' | 'primary';
};

const ActionButton = ({ iconName, content, onClick, variant }: Props) => {
    return (
        <ButtonContainer data-testid="action-button" onClick={onClick} variant={variant}>
            <div className="icon-container">
                <Icon name={iconName} />
            </div>
            <span className="content">{content}</span>
        </ButtonContainer>
    );
};

export default ActionButton;
