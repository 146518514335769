import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { GetTeamMappingsParameters, TeamMappingsResponse } from 'api/types/mapper';

import { MappingKeys } from './queries';

async function getTeamMappings(
    parameters: GetTeamMappingsParameters,
): Promise<TeamMappingsResponse> {
    const url = '{base_url}/{league}/mappings/{source}/{team_id}?status={status}'
        .replace('{base_url}', API.MAPPINGS_API)
        .replace('{league}', parameters.league)
        .replace('{source}', parameters.source)
        .replace('{team_id}', parameters.teamId)
        .replace('{status}', parameters.status ?? 'all');

    return client.get<TeamMappingsResponse>(url).then((response) => response.data);
}

export function useTeamMappingsQuery(parameters: GetTeamMappingsParameters) {
    return useQuery({
        queryKey: MappingKeys.teamMappings(parameters),
        queryFn: () => getTeamMappings(parameters),
        onError: console.error,
        refetchOnWindowFocus: false,
        staleTime: 60 * 60 * 1000, // 1h
        cacheTime: 60 * 60 * 1000, // 1h
    });
}
