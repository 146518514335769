import * as React from 'react';

import { Option } from 'types/controls';

import { IconDecorated, IconKeys } from '../icons';
import Popover from '../popover';

import { DropdownOptionsContainer, DropdownPlaceholder } from './styles';

export type DropdownValue = string | number | object | undefined;

export type Props<T extends DropdownValue> = {
    customStyles?: React.ComponentProps<typeof DropdownOptionsContainer>['css'];
    label?: string;
    labelIcon?: IconKeys;
    minOptionsToSearch?: number;
    onChange: (value: T) => void;
    options: Option<T>[];
    placeholderName?: string;
    searchable?: boolean;
};

const Dropdown = <T extends DropdownValue>({
    customStyles,
    label,
    labelIcon,
    minOptionsToSearch = 15,
    onChange,
    options,
    placeholderName = 'select',
    searchable = false,
}: Props<T>) => {
    const [searchInput, setSearchInput] = React.useState('');
    const placeholderRef = React.useRef<HTMLDivElement>(null!);
    const selectedOption = options.find((option) => option.selected);

    const placeholder = (
        <DropdownPlaceholder ref={placeholderRef} data-testid="dropdown">
            {labelIcon && <IconDecorated sizeRem={1} name={labelIcon} />}
            <span data-testid="dropdown-label">
                {label && `${label}:`} {selectedOption ? selectedOption.label : placeholderName}
            </span>
            <IconDecorated sizeRem={1} name="ArrowDropDown" />
        </DropdownPlaceholder>
    );

    React.useEffect(() => {
        setSearchInput('');
    }, [options]);

    const handleChange = React.useCallback(
        (value, onClose) => {
            onChange(value);
            onClose();
        },
        [onChange],
    );

    const handleSearchInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setSearchInput(event.target.value);
    };

    const currentOptions = options.filter(({ label }) => {
        return !searchable || label?.toLowerCase().includes(searchInput.toLowerCase());
    });

    const anchorVerticalOrigin = placeholderRef.current
        ? placeholderRef.current.offsetHeight + 2
        : 'bottom';

    return (
        <Popover
            placeholder={placeholder}
            anchorOrigin={{ horizontal: 'left', vertical: anchorVerticalOrigin }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
            {(onClose) => {
                return (
                    <DropdownOptionsContainer
                        css={customStyles}
                        data-testid="dropdown-options"
                        placeholderWidth={placeholderRef?.current?.offsetWidth}
                    >
                        {searchable && options.length > minOptionsToSearch && (
                            <div className="input-container">
                                <input
                                    className="input"
                                    data-testid="dropdown-search"
                                    onChange={handleSearchInputChange}
                                    placeholder="Search..."
                                    value={searchInput}
                                />
                            </div>
                        )}
                        <div className="option-list">
                            {currentOptions.map((option) => (
                                <div
                                    key={option.label}
                                    className="option"
                                    onClick={() => handleChange(option.value, onClose)}
                                >
                                    {option.label}
                                </div>
                            ))}
                        </div>
                    </DropdownOptionsContainer>
                );
            }}
        </Popover>
    );
};

export default Dropdown;
