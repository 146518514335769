import { MappingEntity, MappingType } from 'api/types/mapper';

import { sourcesDetails } from '../../constants/sources-details';

export function useMappingEntityAttributes<T>(
    entity: MappingEntity | undefined,
    defaultValue: T,
): Record<string, string | T> {
    if (!entity) {
        return {};
    }

    if (entity.type === MappingType.PLAYER) {
        return {
            'Position:': entity.position ?? defaultValue,
            'Number:': entity.jersey ? `#${entity.jersey}` : defaultValue,
            'DOB:': defaultValue,
            'Year:': defaultValue,
            'Height:': defaultValue,
            'Weight:': defaultValue,
            'Birthplace:': defaultValue,
            'ID:': entity.id,
            'Source:': sourcesDetails[entity.source].label,
        };
    }

    if (entity.type === MappingType.SCHEDULE) {
        return {
            'Away:': defaultValue,
            'Home:': defaultValue,
            'Date:': entity.date ?? defaultValue,
            'Location:': entity.stadium ?? defaultValue,
            'ID:': entity.id,
            'Source:': sourcesDetails[entity.source].label,
        };
    }

    return {};
}
