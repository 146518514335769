import { useMutation, useQueryClient } from 'react-query';

import API from 'api';
import client from 'api/client';
import { MappingType, UpdateTeamMappingsParameters } from 'api/types/mapper';
import { LeagueType, SourceId } from 'types';

import { MappingKeys } from './queries';

async function updateTeamMappings(parameters: UpdateTeamMappingsParameters) {
    const url = '{base_url}/map/{league}/manual/{source}'
        .replace('{base_url}', API.MAPPINGS_API)
        .replace('{league}', parameters.league)
        .replace('{source}', parameters.source);

    return client
        .put(url, {
            league: parameters.league,
            source: parameters.source,
            ...(parameters.type === MappingType.PLAYER && { players: [parameters.mapping] }),
            ...(parameters.type === MappingType.SCHEDULE && { schedules: [parameters.mapping] }),
        })
        .then((response) => response.data);
}

type Parameters = {
    league: LeagueType;
    source: SourceId;
    teamId: string;
    type: MappingType;
};

export function useTeamMappingsMutation(parameters: Parameters) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (mapping: UpdateTeamMappingsParameters['mapping']) =>
            updateTeamMappings({ ...parameters, mapping }),
        onSuccess: () => {
            queryClient.invalidateQueries(
                MappingKeys.team({
                    league: parameters.league,
                    teamId: parameters.teamId,
                }),
            );
        },
        onError: console.error,
        retry: 2,
    });
}
