import * as React from 'react';

import { MappingStatus, MappingType, TeamMappingsStatsResponse } from 'api/types/mapper';

const MAPPINGS_KEY_MAP: Record<MappingType, keyof TeamMappingsStatsResponse> = {
    [MappingType.PLAYER]: 'players',
    [MappingType.SCHEDULE]: 'schedules',
};

export function useTeamMappingsStatsByType(
    stats: TeamMappingsStatsResponse | undefined,
): Record<MappingType, Record<MappingStatus, number>> {
    return React.useMemo(() => {
        const statsEntries = Object.values(MappingType).map((type) => [
            type,
            {
                [MappingStatus.AUTOMATIC]: Object.values(stats?.[MAPPINGS_KEY_MAP[type]] ?? {})
                    .map((source) => source?.autoMapped ?? 0)
                    .reduce((sum, value) => sum + value, 0),
                [MappingStatus.MANUAL]: Object.values(stats?.[MAPPINGS_KEY_MAP[type]] ?? {})
                    .map((source) => source?.manuallyMapped ?? 0)
                    .reduce((sum, value) => sum + value, 0),
                [MappingStatus.UNMAPPED]: Object.values(stats?.[MAPPINGS_KEY_MAP[type]] ?? {})
                    .map((source) => (source?.autoUnmapped ?? 0) + (source.manuallyUnmapped ?? 0))
                    .reduce((sum, value) => sum + value, 0),
            },
        ]);

        return Object.fromEntries(statsEntries);
    }, [stats]);
}
