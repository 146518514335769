import * as React from 'react';

import { MappingType, TeamMappingsStatsResponse } from 'api/types/mapper';
import { SourceId } from 'types';

const MAPPINGS_KEY_MAP: Record<MappingType, keyof TeamMappingsStatsResponse> = {
    [MappingType.PLAYER]: 'players',
    [MappingType.SCHEDULE]: 'schedules',
};

export function useTeamMappingsStatsBySource(
    mappingsStats: TeamMappingsStatsResponse | undefined,
    sources: SourceId[],
    type: MappingType,
) {
    return React.useMemo(() => {
        return sources.map((source) => ({
            source,
            value:
                (mappingsStats?.[MAPPINGS_KEY_MAP[type]]?.[source]?.autoUnmapped ?? 0) +
                (mappingsStats?.[MAPPINGS_KEY_MAP[type]]?.[source]?.manuallyUnmapped ?? 0),
        }));
    }, [mappingsStats, sources, type]);
}
