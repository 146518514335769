import styled from '@emotion/styled';

export const ViewContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const ContentContainer = styled.div<{ size?: 'medium' | 'large' | 'full' }>`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;

    background: var(--bg-content-top-right);
    background-size: 10%;

    & > .inner-container {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
        padding: 1.5rem;
        width: 100%;

        max-width: ${({ size = 'medium' }) => {
            switch (size) {
                case 'full':
                    return '100%';
                case 'large':
                    return '115rem';
                case 'medium':
                    return '64rem';
            }
        }};
    }
`;
