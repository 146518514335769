import { SnackbarProvider } from 'notistack';
import React, { useMemo } from 'react';

import { LocalizationProvider } from './localization';
import { QueryClientProvider } from './query-client';
import { RouteProvider } from './router';
import { ColorSchemeConfigProvider } from './theme/useColorScheme';
import { CurrentSeasonProvider } from './useCurrentSeason';

export const Providers = ({ children }: React.PropsWithChildren<unknown>) => {
    const providers = useMemo(
        () => [
            RouteProvider,
            CurrentSeasonProvider,
            ColorSchemeConfigProvider,
            QueryClientProvider,
            LocalizationProvider,
            SnackbarProvider,
        ],
        [],
    );

    return (
        <>
            {providers.reduceRight(
                (stack, Provider) => (
                    <Provider>{stack}</Provider>
                ),
                children,
            )}
        </>
    );
};
