import * as React from 'react';

import { MappingEntity, MappingStatus, MappingType } from 'api/types/mapper';
import ActionButton from 'components/action-button';
import { IconDecorated } from 'components/icons';
import { SourceId } from 'types';

import { MappingDraggable } from '../mapping-draggable';
import { MappingItemAttributes } from '../mapping-item-attributes';

import { MappingItemContainer } from './styles';

type Props = {
    entity: MappingEntity;
    onMapClick?: (entity: MappingEntity) => void;
    onRemapClick?: (entity: MappingEntity) => void;
    onUnmapClick?: (entity: MappingEntity) => void;
};

export const MappingItem = ({ entity, onMapClick, onRemapClick, onUnmapClick }: Props) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const hasEntityIcon = entity.source === SourceId.SR && entity.mappings.length !== 0;
    const hasErrorIcon =
        (entity.source === SourceId.SR && entity.mappings.length === 0) ||
        (entity.source !== SourceId.SR && entity.status === MappingStatus.UNMAPPED);
    const hasMappings = entity.source === SourceId.SR && entity.mappings.length !== 0;

    const handleChange = React.useCallback(() => {
        if (entity.source === SourceId.SR && entity.mappings.length !== 0) {
            setIsOpen((isOpen) => !isOpen);
        }
    }, [setIsOpen, entity.source, entity.mappings.length]);

    const handleMapClick = React.useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.stopPropagation();
            onMapClick?.(entity);
        },
        [onMapClick, entity],
    );

    return (
        <MappingItemContainer hasMappings={hasMappings}>
            <div className="mapping-item-header" onClick={handleChange}>
                {(hasEntityIcon || hasErrorIcon) && (
                    <div className="mapping-item-icon">
                        {hasErrorIcon && entity.type === MappingType.PLAYER && (
                            <IconDecorated
                                sizeRem={1}
                                name="PersonOffRounded"
                                paletteColor="red600"
                            />
                        )}
                        {hasEntityIcon && !isOpen && entity.type === MappingType.PLAYER && (
                            <IconDecorated
                                sizeRem={1}
                                name="PersonRounded"
                                paletteColor="blue400"
                            />
                        )}
                        {hasEntityIcon && isOpen && entity.type === MappingType.PLAYER && (
                            <IconDecorated sizeRem={1} name="ArrowDropUp" paletteColor="blue400" />
                        )}
                        {hasErrorIcon && entity.type === MappingType.SCHEDULE && (
                            <IconDecorated sizeRem={1} name="EventBusy" paletteColor="red600" />
                        )}
                        {hasEntityIcon && !isOpen && entity.type === MappingType.SCHEDULE && (
                            <IconDecorated
                                sizeRem={1}
                                name="CalendarTodayRounded"
                                paletteColor="yellow600"
                            />
                        )}
                        {hasEntityIcon && isOpen && entity.type === MappingType.SCHEDULE && (
                            <IconDecorated
                                sizeRem={1}
                                name="ArrowDropUp"
                                paletteColor="yellow600"
                            />
                        )}
                    </div>
                )}
                <div className="mapping-item-content">
                    <MappingItemAttributes entity={entity} />
                    {entity.source === SourceId.SR && (
                        <ActionButton
                            content="Add mapping"
                            iconName="SyncAlt"
                            onClick={handleMapClick}
                            variant="default"
                        />
                    )}
                    {entity.source !== SourceId.SR && entity.status === MappingStatus.UNMAPPED && (
                        <ActionButton
                            content="Map"
                            iconName="SyncAlt"
                            onClick={() => onMapClick?.(entity)}
                            variant="default"
                        />
                    )}
                    {entity.source !== SourceId.SR && entity.status !== MappingStatus.UNMAPPED && (
                        <div className="mapping-item-button-group">
                            <ActionButton
                                content="Unmap"
                                iconName="SyncAlt"
                                onClick={() => onUnmapClick?.(entity)}
                                variant="default"
                            />
                            <ActionButton
                                content="Remap"
                                iconName="SyncAlt"
                                onClick={() => onRemapClick?.(entity)}
                                variant="default"
                            />
                        </div>
                    )}
                </div>
            </div>
            {isOpen && entity.status !== MappingStatus.UNMAPPED && (
                <div className="mapping-item-mappings-list">
                    {entity.mappings.map((mapping) => (
                        <MappingDraggable key={mapping.id} data={mapping} id={mapping.id}>
                            <MappingItem
                                entity={mapping}
                                onRemapClick={onRemapClick}
                                onUnmapClick={onUnmapClick}
                            />
                        </MappingDraggable>
                    ))}
                </div>
            )}
        </MappingItemContainer>
    );
};
