import Chip, { ChipVariant } from 'components/chip';
import { Coverage, coverageMap } from 'types/coverage';

export const GAME_COVERAGE_VARIANTS: Record<Coverage, ChipVariant> = {
    [Coverage.Full]: 'successSecondary',
    [Coverage.Boxscore]: 'warningTertiary',
    [Coverage.ExtendedBoxscore]: 'warningTertiary',
};

type Props = {
    coverage?: string;
};

export const GameCoverageChip = ({ coverage }: Props) => {
    return (
        <Chip variant={GAME_COVERAGE_VARIANTS[coverage as Coverage] ?? 'errorPrimary'}>
            Coverage: {coverageMap[coverage as Coverage] ?? 'Unknown'}
        </Chip>
    );
};
