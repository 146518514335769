import { mqtt5 } from 'aws-iot-device-sdk-v2';
import * as React from 'react';

import Env from '../lib/env';
import WsContext from '../ws/ws-context';

/**
 * Subscribe to a topic
 * @param service the service name
 * @param topic must start with `/
 * @param qos how mqtt handles the message 0 pretty fast but less risk of delivery, 1 at least once, 2 exactly once
 *
 * @see https://docs.oasis-open.org/mqtt/mqtt/v5.0/os/mqtt-v5.0-os.html#_Toc3901234
 */

export function useSubscribeTopic(service: string, topic: string, qos: mqtt5.QoS) {
    const { subscribe, unsubscribe, connected } = React.useContext(WsContext);
    const stage = Env.get('REACT_APP_STAGE');

    const topicWithStage = React.useMemo(
        () => `/stage:${stage}/service:${service}${topic}`,
        [service, stage, topic],
    );

    React.useEffect(() => {
        if (!connected) {
            return;
        }

        subscribe(topicWithStage, qos);

        return () => {
            unsubscribe(topicWithStage);
        };
    }, [subscribe, unsubscribe, connected, topicWithStage, qos]);
}

export function useSubscribeTopicLazy() {
    const { subscribe, unsubscribe, connected } = React.useContext(WsContext);
    const stage = Env.get('REACT_APP_STAGE');

    const subscribeTopic = React.useCallback(
        (service: string, topic: string, qos: mqtt5.QoS) => {
            if (!connected) {
                return;
            }

            const topicWithStage = `/stage:${stage}/service:${service}${topic}`;

            subscribe(topicWithStage, qos);
        },
        [connected, stage, subscribe],
    );

    const unsubscribeTopic = React.useCallback(
        (service: string, topic: string) => {
            if (!connected) {
                return;
            }

            const topicWithStage = `/stage:${stage}/service:${service}${topic}`;

            unsubscribe(topicWithStage);
        },
        [connected, stage, unsubscribe],
    );

    return { subscribeTopic, unsubscribeTopic };
}
