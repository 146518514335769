import { SourceId } from '../../types';

import { Game } from './bff';

export namespace LiveFlags {
    export enum FlagStatus {
        AUTOMATICALLY_RESOLVED = 'AUTOMATICALLY_RESOLVED',
        CREATED = 'CREATED',
        IGNORED = 'IGNORED',
        RESOLVED = 'RESOLVED',
    }

    export function isFlagStatus(flagStatus: unknown): flagStatus is FlagStatus {
        return Object.values(FlagStatus).includes(flagStatus as FlagStatus);
    }

    export enum Context {
        Team = 'team',
        Player = 'player',
    }

    export const FLAG_STATUS_MAP: Record<FlagStatus, string> = {
        [FlagStatus.AUTOMATICALLY_RESOLVED]: 'Resolved',
        [FlagStatus.CREATED]: 'Created',
        [FlagStatus.IGNORED]: 'Ignored',
        [FlagStatus.RESOLVED]: 'Resolved',
    };

    export enum LiveFlagCategoryName {
        Individual = 'individual',
        Team = 'team',
    }

    export function isLiveFlagCategoryName(
        categoryName: unknown,
    ): categoryName is LiveFlagCategoryName {
        return Object.values(LiveFlagCategoryName).includes(categoryName as LiveFlagCategoryName);
    }

    export type LiveFlagCategory = {
        categoryName: LiveFlagCategoryName;
        types: string[];
    };

    export type DiscrepancyStatusUpdateRequest = {
        id: string;
        status: FlagStatus.CREATED | FlagStatus.IGNORED | FlagStatus.RESOLVED;
        ignoreReason?: string;
        ignoreComment?: string;
    };

    export type StatusCount = {
        CREATED: number;
        IGNORED: number;
    };

    export type GameFlagStats = Record<string, { statusCount: StatusCount }>;

    export type LiveFlag = {
        id: string;
        context: Context;
        contextSrIds: {
            game: string | null;
            player: string | null;
            team: string | null;
        };
        srValue: string | number | null;
        externalValue: string | number | null;
        name: string;
        status: FlagStatus;
        timestamp: number;
        source: SourceId;
        ignoreReason?: string;
        ignoreComment?: string;
    };

    export type LiveFlagWSUpdateEvent = {
        toDelete: {
            pk: string;
            sk: string[];
        };
        toPut: Game.LiveGameFlag[];
    };
}
