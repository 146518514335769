import { Data, useDroppable } from '@dnd-kit/core';
import * as React from 'react';

import { MappingDroppableContainer } from './styles';

type Props = {
    children: React.ReactNode;
    className?: string;
    data?: object;
    getIsOver?: (activeData?: Data, overId?: string | number, overData?: Data) => boolean;
    id: string;
};

export const MappingDroppable = (props: Props) => {
    const droppable = useDroppable({ id: props.id, data: props.data });

    const isOver = React.useMemo(() => {
        if (!props.getIsOver) {
            return droppable.isOver;
        }

        if (!droppable.isOver) {
            return false;
        }

        return props.getIsOver(
            droppable.active?.data?.current,
            droppable.over?.id,
            droppable.over?.data?.current,
        );
    }, [droppable.active?.data, droppable.isOver, droppable.over?.data, droppable.over?.id, props]);

    return (
        <MappingDroppableContainer
            className={props.className}
            isOver={isOver}
            ref={droppable.setNodeRef}
        >
            {props.children}
        </MappingDroppableContainer>
    );
};
