import { SourceId } from 'types';

import { sourcesDetails } from '../../constants/sources-details';

import { ButtonsContainer, SourceButton } from './styles';
import { ValuesBySource } from './types';

type Props = {
    valuesBySource: ValuesBySource;
    onSourceClick: (source: SourceId) => void;
    activeSource: SourceId | null;
};

const SourceSelector = ({ valuesBySource, onSourceClick, activeSource }: Props) => {
    if (!valuesBySource.length) {
        return null;
    }

    return (
        <ButtonsContainer data-testid="source-selector-container">
            {valuesBySource.map(({ source, value }) => (
                <SourceButton
                    key={`source-btn-${source}`}
                    active={source === activeSource}
                    onClick={() => onSourceClick(source)}
                    data-testid="source-selector-button"
                >
                    <span data-testid="source-selector-title">{sourcesDetails[source].label}</span>
                    {value !== null && (
                        <span className="value" data-testid="source-selector-value">
                            ({value})
                        </span>
                    )}
                </SourceButton>
            ))}
        </ButtonsContainer>
    );
};

export default SourceSelector;
