import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const TooltipContainer = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))`
    & .${tooltipClasses.tooltip} {
        border: 1px solid var(--color-borderline);
        box-shadow: 0 2px 4px 0 var(--blue2000);
        background-color: var(--color-background);
        color: var(--color-text);
    }
`;
