import { LeagueType, SourceId } from 'types';

export enum MappingStatus {
    AUTOMATIC = 'automatic',
    MANUAL = 'manual',
    UNMAPPED = 'unmapped',
}

export enum MappingType {
    PLAYER = 'player',
    SCHEDULE = 'schedule',
}

export function isMappingType(mappingType: unknown): mappingType is MappingType {
    return Object.values(MappingType).includes(mappingType as MappingType);
}

export type MappingSchedule = {
    id: string;
    srId?: string;
    name?: string;
    date?: string;
    stadium?: string;
    status?: MappingStatus;
    source: SourceId;
    mappings: MappingSchedule[];
    type: MappingType.SCHEDULE;
};

export type MappingPlayer = {
    id: string;
    srId?: string;
    name?: string;
    jersey?: number;
    position?: string;
    status?: MappingStatus;
    source: SourceId;
    mappings: MappingPlayer[];
    type: MappingType.PLAYER;
};

export type MappingEntity = MappingSchedule | MappingPlayer;

export type GetTeamMappingsParameters = {
    league: LeagueType;
    source: SourceId;
    teamId: string;
    status?: MappingStatus;
};

export type TeamMappingsResponse = {
    players?: {
        id: string;
        name: string;
        jersey: number;
        position: string;
        dateOfBirth?: string;
        year?: string;
        height?: string;
        weight?: string;
        birthPlace?: string;
        source: SourceId;
        mapped: string | null;
    }[];
    schedules?: {
        name: string;
        date: string;
        stadium: string;
        id: string;
        source: SourceId;
        mapped: string | null;
    }[];
};

export type UpdateTeamMappingsParameters = {
    league: LeagueType;
    source: SourceId;
    teamId: string;
    type: MappingType;
    mapping: {
        id: string;
        srId: string | null;
    };
};

export type GetTeamMappingsStatsParameters = {
    league: LeagueType;
    teamId: string;
};

export type TeamMappingsStatsResponse = {
    players: TeamMappingsSourceStats;
    schedules: TeamMappingsSourceStats;
};

export type TeamMappingsSourceStats = Record<
    SourceId,
    {
        autoMapped: number;
        autoUnmapped: number;
        manuallyMapped: number;
        manuallyUnmapped: number;
    }
>;
