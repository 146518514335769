import styled from '@emotion/styled';

export const GameMappingIndicatorTooltipContainer = styled.div`
    .mapping-tooltip-attribute {
        color: var(--color-text);
        font-size: 0.75rem;
        line-height: 0.5rem;
        font-weight: 700;
    }

    .mapping-tooltip-value {
        opacity: 0.6;
    }
`;

export const GameMappingIndicatorContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 18rem;
    min-width: 6rem;
    overflow-y: auto;
    background-color: var(--color-background);
    border-radius: 0.2rem;
    border: 0.0625rem solid var(--color-dropdown-border);
    color: var(--color-text);
    font-size: 0.75rem;
    text-transform: uppercase;

    &.loading-container {
        overflow: hidden;
        padding: 0.75rem 0.4375rem;
    }

    &.label-container {
        padding: 0.25rem 0.4375rem;
    }

    &.teams-container {
        gap: 0.5rem;
        padding: 0.5rem 0.75rem 0.5rem 0.25rem;

        & .team-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
            cursor: pointer;
            width: 100%;

            & > .team-crest {
                display: inline-block;
                width: 2rem;
                height: 1.5rem;
            }

            &:hover {
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }
`;
