import { colours } from '../../app/theme/color-scheme';
import { IconDecorated, IconKeys } from '../icons';

import { IconContainer, StatContainer } from './styles';

type Props = {
    label: string;
    value: string | number;
    iconKey?: IconKeys;
    color?: string;
    opacity?: number;
    sizeRem?: number;
    paletteColor?: keyof typeof colours;
};

const Stat = ({
    label,
    value,
    iconKey = 'Flag',
    color = 'red500',
    opacity = 0.05,
    sizeRem = 0.725,
    paletteColor = 'red600',
}: Props) => {
    return (
        <StatContainer>
            <IconContainer color={color} opacity={opacity}>
                <IconDecorated sizeRem={sizeRem} name={iconKey} paletteColor={paletteColor} />
            </IconContainer>
            <span>
                {label}: {value}
            </span>
        </StatContainer>
    );
};

export default Stat;
