import { LiveFlags } from 'api/types/live-flags';
import { SeasonType, SeasonYear } from 'types';

export type DiscrepancyStatus = Exclude<
    LiveFlags.FlagStatus,
    LiveFlags.FlagStatus.AUTOMATICALLY_RESOLVED
>;

export type Discrepancy = {
    id: string;
    status: DiscrepancyStatus;
    createdAt: string;
    actualValue: number | string | null;
    expectedValue: number | string | null;
    context: 'team' | 'player';
    flagName: string;
    flagGroupName: string | null;
    flagTypeName: string;
    teamId: string | null;
    teamName: string | null;
    teamAlias: string | null;
    teamQualifier: 'away' | 'home';
    playerName?: string | null;
    ignoreReason?: DiscrepancyIgnoreReason;
    ignoreComment?: string;
};

export type TeamInterface = {
    id?: string;
    name?: string;
};

export enum ViewType {
    LIVE_GAME = 'LIVE_GAME',
    POST_GAME = 'POST_GAME',
    AWAY_MAPPINGS = 'AWAY_MAPPINGS',
    HOME_MAPPINGS = 'HOME_MAPPINGS',
}

export type GetGameQueryParams = {
    seasonYear: SeasonYear;
    seasonType: SeasonType;
    limit: string;
    status?: string;
    context?: string;
    statName?: string;
};

export enum DiscrepancyIgnoreReason {
    CALCULATED_DIFFERENTLY = 'CALCULATED_DIFFERENTLY',
    NOT_IMPORTANT = 'NOT_IMPORTANT',
    TECHNICAL_ERROR = 'TECHNICAL_ERROR',
    OTHER = 'OTHER',
}

export type DirectLinkURLs = {
    awayUrl: string | null;
    homeUrl: string | null;
};
