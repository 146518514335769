import { QueryClient } from 'react-query';

import { Games, Game } from 'api/types/bff';

import { LeagueKeys } from '../../hooks/queries';
import { WSBackendEvent } from '../types';

import { MessageProcessor } from './types';

export enum BFFTopicTypes {
    GAME_UPDATE = 'GAME_UPDATE',
    GAMES_UPDATE = 'GAMES_UPDATE',
}

export const bffProcessor: MessageProcessor = async (
    queryClient: QueryClient,
    payload: object,
): Promise<void> => {
    const gameMessage = payload as WSBackendEvent<Games.Game>;

    switch (gameMessage.type) {
        case BFFTopicTypes.GAME_UPDATE:
            return gameProcessor(queryClient, payload);
        case BFFTopicTypes.GAMES_UPDATE:
            return cgetGamesProcessor(queryClient, payload);
        default:
            console.error('BffProcessor: unknown message type: %s', gameMessage.type);
    }
};

const gameProcessor: MessageProcessor = async (
    queryClient: QueryClient,
    payload: object,
): Promise<void> => {
    type GameWithLeague = Game.LiveGame & { league: string };

    const { payload: game } = payload as WSBackendEvent<GameWithLeague>;

    const queriesData = queryClient.getQueriesData<GameWithLeague | undefined>(
        LeagueKeys.getGamePartial(game.league, game.srId),
    );

    queriesData.forEach((queryData) => {
        const queryKey = Array.from(queryData[0]) as Array<string | undefined>;

        queryClient.setQueryData<Game.Game | undefined>(queryKey, (oldData) => {
            if (!oldData) {
                return {
                    ...game,
                    liveFlags: [],
                    flagStats: {
                        CREATED: 0,
                        IGNORED: 0,
                    },
                };
            }

            return {
                ...oldData,
                ...game,
            };
        });
    });
};

const cgetGamesProcessor: MessageProcessor = async (
    queryClient: QueryClient,
    payload: object,
): Promise<void> => {
    const gameMessage = payload as WSBackendEvent<Games.Game>;
    const game = gameMessage.payload;

    const queriesData = queryClient.getQueriesData<Games.Game[] | undefined>(
        LeagueKeys.getGamesPartial(game.league),
    );

    // get all the queries related to this game
    queriesData.forEach((queryData) => {
        const queryKey = Array.from(queryData[0]) as Array<string | undefined>;

        const qKstatus = queryKey.find((key) => key?.startsWith('status'))?.split(':')[1];
        const qKdivision = queryKey.find((key) => key?.startsWith('division'))?.split(':')[1];
        const qKcoverage = queryKey.find((key) => key?.startsWith('coverage'))?.split(':')[1];

        // there are filters on this page so we need to take them into account
        if (qKstatus && qKcoverage && qKdivision) {
            // if the game matches the filters add it to the cache if not remove it
            if (
                ['all', game.status].includes(qKstatus) &&
                ['all', game.coverage].includes(qKcoverage) &&
                ['all', game.awayTeamDivision, game.homeTeamDivision].includes(qKdivision)
            ) {
                queryClient.setQueryData<Games.Game[] | undefined>(queryKey, (oldData) => {
                    if (!oldData) {
                        return [game];
                    }

                    // game doesn't exist in cache so add it
                    if (!oldData.find((oldGame) => oldGame.id === game.id)) {
                        return [...oldData, game];
                    }

                    // game exists in cache so update it
                    return oldData.map((oldGame) => {
                        if (oldGame.id === game.id) {
                            return game;
                        }

                        return oldGame;
                    });
                });
            } else {
                queryClient.setQueryData<Games.Game[] | undefined>(queryKey, (oldData) => {
                    if (!oldData) {
                        return oldData;
                    }

                    return oldData.filter((oldGame) => oldGame.id !== game.id);
                });
            }
        }
    });
};
