import * as React from 'react';

import { MappingEntity, MappingStatus, MappingType } from 'api/types/mapper';
import Dropdown from 'components/dropdown';
import Checkbox from 'components/primitives/checkbox';
import { useMappingSortingOptions } from 'hooks/mapping/useMappingSortingOptions';
import { Order } from 'types/sorting';

import { MappingColumnContainer } from './styles';

function createSearchValue(entity: MappingEntity) {
    if (entity.type === MappingType.PLAYER) {
        return `${entity.id}${entity.srId}${entity.name}${entity.position}#${entity.jersey}`.toLocaleLowerCase();
    }

    if (entity.type === MappingType.SCHEDULE) {
        return `${entity.id}${entity.srId}${entity.name}${entity.date}${entity.stadium}`.toLocaleLowerCase();
    }

    return '';
}

type Props = {
    children: (entities: MappingEntity[]) => React.ReactNode;
    hasUnmappedCheckbox?: boolean;
    entities: MappingEntity[];
    title: string;
};

export const MappingColumn = (props: Props) => {
    const [order, setOrder] = React.useState(Order.Asc);
    const orderOptions = useMappingSortingOptions(order);

    const [searchInput, setSearchInput] = React.useState('');
    const [checked, setChecked] = React.useState(props.hasUnmappedCheckbox ?? false);

    const handleSearchInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setSearchInput(event.target.value);
    };

    const handleCheckboxChange = React.useCallback(() => {
        setChecked((checked) => !checked);
    }, [setChecked]);

    const entities = React.useMemo(() => {
        return props.entities
            .filter((e) => (checked ? e.status === MappingStatus.UNMAPPED : true))
            .filter((e) => createSearchValue(e).includes(searchInput.toLocaleLowerCase()))
            .sort((a, b) =>
                order === Order.Asc
                    ? (a.name ?? '').localeCompare(b.name ?? '')
                    : (b.name ?? '').localeCompare(a.name ?? ''),
            );
    }, [props.entities, checked, searchInput, order]);

    return (
        <MappingColumnContainer>
            <div className="mapping-column-header">
                <p className="mapping-column-title">{props.title}</p>
                <div className="mapping-column-controls">
                    <div className="mapping-column-controls-group">
                        <Dropdown label="Sort" onChange={setOrder} options={orderOptions} />
                        <input
                            className="mapping-column-input"
                            onChange={handleSearchInputChange}
                            placeholder="Search..."
                            value={searchInput}
                        />
                    </div>
                    {props.hasUnmappedCheckbox && (
                        <Checkbox
                            checked={checked}
                            label="Unmapped only"
                            onChange={handleCheckboxChange}
                        />
                    )}
                </div>
            </div>
            <div className="mapping-column-list">{props.children(entities)}</div>
        </MappingColumnContainer>
    );
};
