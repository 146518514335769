import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { Gradient } from 'app/theme/global-styles';
import HeaderImage from 'assets/headernav-bg-left.png';

export const HeaderContainer = styled.div`
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4rem;
    padding: 0 1.125rem 0 1.25rem;
    background: url(${HeaderImage}) top left/12rem 100% no-repeat, ${Gradient};

    & > .league-settings-group {
        display: flex;
        align-items: center;
        column-gap: 1.5rem;
    }

    & > .preferences-settings-group {
        display: flex;
        align-items: center;
        column-gap: 1rem;
    }
`;

const bounceAnimation = keyframes`
  from, 20%, 53%, 80%, to {
    transform: rotateZ(0deg)
  }

  90% {
    transform: rotateZ(180deg)
  }
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    height: 100%;
    cursor: pointer;

    & > .uapi-logo {
        animation: ${bounceAnimation} 10s ease infinite;
    }

    & > .uapi-title {
        font-family: Calistoga, Roboto, sans-serif;
        font-size: 1.375rem;
        color: var(--white);
    }
`;
