import * as React from 'react';

import {
    MappingEntity,
    MappingPlayer,
    MappingSchedule,
    MappingStatus,
    MappingType,
    TeamMappingsResponse,
} from 'api/types/mapper';
import { SourceId } from 'types';

type TeamMappings = {
    external: MappingEntity[];
    internal: MappingEntity[];
};

export function useTeamMappings(
    teamsMappings: TeamMappingsResponse | undefined,
    type: MappingType,
): TeamMappings {
    return React.useMemo(() => {
        let entities: MappingEntity[] = [];

        if (type === MappingType.PLAYER) {
            entities = (teamsMappings?.players ?? []).map(
                (player): MappingPlayer => ({
                    id: player.id,
                    srId: (player.source === SourceId.SR ? player.id : player.mapped) ?? undefined,
                    name: player.name,
                    jersey: player.jersey,
                    position: player.position,
                    source: player.source,
                    mappings: [],
                    type: MappingType.PLAYER,
                }),
            );
        }

        if (type === MappingType.SCHEDULE) {
            entities = (teamsMappings?.schedules ?? []).map(
                (player): MappingSchedule => ({
                    id: player.id,
                    srId: (player.source === SourceId.SR ? player.id : player.mapped) ?? undefined,
                    name: player.name,
                    date: player.date,
                    stadium: player.stadium,
                    source: player.source,
                    mappings: [],
                    type: MappingType.SCHEDULE,
                }),
            );
        }

        return {
            external: entities
                .filter((entity) => entity.source !== SourceId.SR && !entity.srId)
                .map((entity) => ({ ...entity, status: MappingStatus.UNMAPPED })),
            internal: entities
                .filter((entity) => entity.source === SourceId.SR)
                .map((entity): MappingEntity => {
                    const mappingEntities = entities.filter(
                        (mapping) => mapping.source !== SourceId.SR && mapping.srId === entity.id,
                    );

                    return {
                        ...entity,
                        // The error is triggered because it thinks `mappingEntities` and `entity` are incompatible.
                        // For example, that MappingSchedule[] is being assigned to MappingPlayer.
                        // @ts-ignore
                        mappings: mappingEntities,
                        status: mappingEntities.length
                            ? MappingStatus.AUTOMATIC
                            : MappingStatus.UNMAPPED,
                    };
                }),
        };
    }, [teamsMappings, type]);
}
