import { GetTeamMappingsParameters, GetTeamMappingsStatsParameters } from 'api/types/mapper';
import { LeagueType } from 'types';

export const MappingKeys = {
    team: ({ league, teamId }: { league: LeagueType; teamId: string }) => {
        return [league, 'mappings', 'teams', teamId];
    },
    teamMappings: ({ league, source, teamId, status }: GetTeamMappingsParameters) => {
        return [league, 'mappings', 'teams', teamId, source, status];
    },
    teamMappingsStats: ({ league, teamId }: GetTeamMappingsStatsParameters) => {
        return [league, 'mappings', 'teams', teamId, 'stats'];
    },
};
