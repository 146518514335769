import { mqtt5 } from 'aws-iot-device-sdk-v2';
import * as React from 'react';
import { Route } from 'type-route';

import { GameStatus } from 'api/types/leagues';
import { MappingType } from 'api/types/mapper';
import { routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import ButtonGoBack from 'components/go-back-button';
import Loading from 'components/loading';
import { MappingColumns } from 'components/mapping/mapping-columns';
import { useChangeParams } from 'hooks/useChangeParams';
import usePrevious from 'hooks/usePrevious';
import { ExternalSourceId, SourceId } from 'types';

import { useSubscribeTopic } from '../../../../app/useSubscribeTopic';

import GameOverviewHeader from './components/game-overview-header';
import GameViewTabs from './components/game-view-tabs';
import LiveGameSection from './components/live-game-section';
import { isInLimits, StatusConfigs } from './components/live-game-section/constants';
import PostGameSection from './components/post-game-section';
import useLiveGame from './hooks/useLiveGame';
import { GameContainer } from './styles';
import { ViewType } from './types';

type Props = {
    route: Route<typeof routes.gameBoxscoresGame>;
};

const gameRefetchInterval = 60 * 1000;

const BoxscoreGame = ({ route }: Props) => {
    const leagueDetails = useLeagueDetails(route.params.league);

    const liveSource = route.params.liveSource ?? leagueDetails.supportedLiveGameSources[0];

    const [gameView, setGameView] = React.useState<ViewType>();

    const limit =
        route.params.liveLimit && isInLimits(route.params.liveStatus, route.params.liveLimit)
            ? route.params.liveLimit
            : StatusConfigs[route.params.liveStatus].defaultLimit;

    const liveGameQuery = useLiveGame(route.params.id, route.params.league, liveSource, {
        seasonYear: route.params.seasonYear,
        seasonType: route.params.seasonType,
        limit: `${limit}`,
        status: route.params.liveStatus ?? 'all',
        context: route.params.liveCategory ?? 'all',
        statName: route.params.liveType ?? 'all',
    });

    const topic = React.useMemo(() => {
        return `/get-games/${route.params.league}`;
    }, [route.params.league]);

    useSubscribeTopic('bff', topic, mqtt5.QoS.AtLeastOnce);

    // Needed so the whole page doesn't load every time we change React Query key.
    const liveGame = usePrevious(liveGameQuery?.data);

    React.useEffect(() => {
        if (liveGame?.awayTeam?.alias && liveGame?.homeTeam?.alias) {
            window.document.title = `${liveGame?.awayTeam?.alias} @ ${liveGame?.homeTeam?.alias}`;
        }

        return () => {
            window.document.title = 'UAPI Monitor';
        };
    }, [liveGame]);

    const hasGameFinished = liveGame?.status
        ? liveGame?.status === GameStatus.Complete || liveGame?.status === GameStatus.Closed
        : undefined;

    const handleParamsChange = useChangeParams(route);

    const handleGoBack = React.useCallback(() => {
        routes
            .gameBoxscoresGames({
                league: route.params.league,
                seasonType: route.params.seasonType,
                seasonYear: route.params.seasonYear,
            })
            .push();
    }, [route.params]);

    const handleSourceChange = React.useCallback(
        (sourceId: SourceId) => handleParamsChange({ source: sourceId as ExternalSourceId }),
        [handleParamsChange],
    );

    React.useEffect(() => {
        if (hasGameFinished !== undefined) {
            setGameView(hasGameFinished ? ViewType.POST_GAME : ViewType.LIVE_GAME);
        }
    }, [hasGameFinished]);

    const handleTeamViewOpen = React.useCallback(
        (teamId: string) => {
            window.open(
                routes.teamsTeamMapping({
                    league: route.params.league,
                    seasonType: route.params.seasonType,
                    seasonYear: route.params.seasonYear,
                    type: MappingType.SCHEDULE,
                    id: teamId,
                }).href,
                '_blank',
            );
        },
        [route.params.league, route.params.seasonType, route.params.seasonYear],
    );

    React.useEffect(() => {
        const refetchCountDown = setInterval(async () => {
            if (
                liveGame?.status === GameStatus.Halftime ||
                liveGame?.status === GameStatus.InProgress
            ) {
                await liveGameQuery.refetch();
            }
        }, gameRefetchInterval);

        return () => clearInterval(refetchCountDown);
    }, [liveGame?.status, liveGameQuery]);

    if (!liveGame) {
        return <Loading mt={10} />;
    }

    return (
        <GameContainer data-testid="game-container">
            <div>
                <ButtonGoBack
                    className="go-back-button"
                    content={`Back to games: ${route.params.seasonYear}`}
                    onClick={handleGoBack}
                />
                <GameOverviewHeader league={route.params.league} game={liveGame} />
            </div>
            {gameView && (
                <GameViewTabs
                    league={route.params.league}
                    awayTeamId={liveGame.awayTeam?.srId}
                    awayTeamGlobalId={liveGame.awayTeam?.srGlobalId}
                    awayTeamName={liveGame.awayTeam?.name}
                    awayTeamAlias={liveGame.awayTeam?.alias}
                    homeTeamId={liveGame.homeTeam?.srId}
                    homeTeamGlobalId={liveGame.homeTeam?.srGlobalId}
                    homeTeamName={liveGame.homeTeam?.name}
                    homeTeamAlias={liveGame.homeTeam?.alias}
                    gameHasFinished={hasGameFinished}
                    externalSchedules={liveGame.externalSchedules}
                    onTeamViewOpen={handleTeamViewOpen}
                    gameView={gameView}
                    onChange={setGameView}
                />
            )}
            <div>
                {liveGameQuery.isLoading && <Loading mt={10} />}
                {!liveGameQuery.isLoading && gameView === ViewType.POST_GAME && (
                    <PostGameSection route={route} />
                )}
                {!liveGameQuery.isLoading && gameView === ViewType.LIVE_GAME && (
                    <LiveGameSection
                        game={liveGame}
                        liveSource={liveSource}
                        route={route}
                        updatedAt={liveGameQuery.dataUpdatedAt}
                        onRefreshClick={liveGameQuery.refetch}
                        isRefreshing={liveGameQuery.isFetching}
                    />
                )}
                {!liveGameQuery.isLoading &&
                    liveGame.awayTeam?.srId &&
                    gameView === ViewType.AWAY_MAPPINGS && (
                        <MappingColumns
                            league={route.params.league}
                            source={route.params.source ?? leagueDetails.mappingSiteSources[0]}
                            sources={leagueDetails.mappingSiteSources}
                            onSourceChange={handleSourceChange}
                            teamId={liveGame.awayTeam?.srId}
                            type={MappingType.PLAYER}
                        />
                    )}
                {!liveGameQuery.isLoading &&
                    liveGame.homeTeam?.srId &&
                    gameView === ViewType.HOME_MAPPINGS && (
                        <MappingColumns
                            league={route.params.league}
                            source={route.params.source ?? leagueDetails.mappingSiteSources[0]}
                            sources={leagueDetails.mappingSiteSources}
                            onSourceChange={handleSourceChange}
                            teamId={liveGame.homeTeam?.srId}
                            type={MappingType.PLAYER}
                        />
                    )}
            </div>
        </GameContainer>
    );
};

export default BoxscoreGame;
