import { useCallback, useMemo, useRef } from 'react';

import { IconDecorated } from 'components/icons';
import Loading from 'components/loading';
import Popover from 'components/popover';
import TeamImage from 'components/team-image';
import { sourcesDetails } from 'constants/sources-details';
import { SourceId } from 'types';

import Tooltip from '../primitives/tooltip';

import { GameMappingIndicatorContainer, GameMappingIndicatorTooltipContainer } from './styles';

type Team = {
    srId: string | null;
    globalId: string | null;
    name: string;
    abbreviation: string;
};

type Props = {
    teams: Team[];
    externalSchedules: {
        id: string;
        source: string;
    }[];
    isLoading?: boolean;
    onTeamViewOpen: (teamId: string) => void;
};

const GameMappingIndicator = ({ teams, externalSchedules, onTeamViewOpen, isLoading }: Props) => {
    const placeholderRef = useRef<HTMLDivElement>(null!);

    const color = externalSchedules.length ? '--color-mapping-links' : '--color-mapping-links-off';
    const icon = externalSchedules.length ? 'Link' : 'LinkOff';

    const tooltipTitle = useMemo(() => {
        if (isLoading) {
            return (
                <GameMappingIndicatorTooltipContainer>
                    Loading...
                </GameMappingIndicatorTooltipContainer>
            );
        }

        if (!isLoading && externalSchedules.length < 1) {
            return (
                <GameMappingIndicatorTooltipContainer>
                    No mappings
                </GameMappingIndicatorTooltipContainer>
            );
        }

        return (
            <GameMappingIndicatorTooltipContainer>
                {externalSchedules.map(({ id, source }) => (
                    <p key={id} className="mapping-tooltip-attribute">
                        <span>{sourcesDetails[source as SourceId].label}: </span>
                        <span className="mapping-tooltip-value">{id}</span>
                    </p>
                ))}
            </GameMappingIndicatorTooltipContainer>
        );
    }, [isLoading, externalSchedules]);

    const anchorVerticalOrigin = placeholderRef.current
        ? placeholderRef.current.offsetHeight + 2
        : 'bottom';

    const renderPopoverContent = useCallback(() => {
        if (isLoading) {
            return (
                <GameMappingIndicatorContainer className="loading-container">
                    <Loading />
                </GameMappingIndicatorContainer>
            );
        }

        if (!isLoading && teams.length < 1) {
            return (
                <GameMappingIndicatorContainer className="label-container">
                    No teams
                </GameMappingIndicatorContainer>
            );
        }

        return (
            <GameMappingIndicatorContainer className="teams-container">
                {teams.map(({ srId, globalId, name, abbreviation }) => (
                    <div
                        className="team-item"
                        key={globalId}
                        onClick={() => srId && onTeamViewOpen(srId)}
                    >
                        <div className="team-crest">
                            <TeamImage srGlobalId={globalId} />
                        </div>
                        <span>
                            {name} ({abbreviation})
                        </span>
                    </div>
                ))}
            </GameMappingIndicatorContainer>
        );
    }, [isLoading, onTeamViewOpen, teams]);

    return (
        <Popover
            placeholder={
                <Tooltip title={tooltipTitle} disableInteractive>
                    <div ref={placeholderRef} data-testid="game-mapping-indicator">
                        <IconDecorated sizeRem={1.25} name={icon} schemeColor={color} />
                    </div>
                </Tooltip>
            }
            anchorOrigin={{ horizontal: 'left', vertical: anchorVerticalOrigin }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
            {renderPopoverContent}
        </Popover>
    );
};

export default GameMappingIndicator;
