import { LeagueType, SeasonType, SeasonYear } from 'types';

export type GetLeagueTeamsParameters = {
    league: LeagueType;
    seasonYear: SeasonYear;
    seasonType: SeasonType;
};

export const leagueTeamsKeys = {
    leagueTeams: ({ league, seasonYear, seasonType }: GetLeagueTeamsParameters) => [
        league,
        'league',
        'teams',
        seasonYear,
        seasonType,
    ],
};
