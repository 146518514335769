import styled from '@emotion/styled';

export const Button = styled.button`
    display: inline-block;
    min-width: fit-content;
    border: none;
    background: none;
    cursor: pointer;
    color: inherit;
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 0.75rem;
    transition: opacity 100ms;

    :disabled {
        cursor: not-allowed;
        opacity: 0.3;
    }
`;
