import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { Games } from 'api/types/bff';

import { GetGamesParameters, LeagueKeys } from './queries';

const getGames = async ({
    coverage,
    dateFrom,
    dateTo,
    divisionAlias,
    league,
    seasonType,
    seasonYear,
    status,
    week,
}: GetGamesParameters): Promise<Games.Game[]> => {
    const searchParams = new URLSearchParams({
        ...(coverage && { coverage }),
        ...(divisionAlias && { divisionAlias }),
        ...(status && { status }),
        ...(week && { week }),
        ...(dateFrom && { dateFrom }),
        ...(dateTo && { dateTo }),
        seasonType,
        seasonYear,
    });
    const response = await client.get<Games.Game[]>(
        `${API.BFF_API}/${league}/games?${searchParams}`,
    );

    return response?.data;
};

export default function useGames(parameters: GetGamesParameters) {
    return useQuery(LeagueKeys.getGames(parameters), () => getGames(parameters), {
        refetchOnWindowFocus: false,
        refetchInterval: 1000 * 60,
        refetchIntervalInBackground: true,
        staleTime: 60 * 1000, // 1 min
        cacheTime: 5 * 60 * 1000, // 5 min
    });
}
