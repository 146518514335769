import { MappingEntity, MappingType } from 'api/types/mapper';
import { SourceId } from 'types';

import { MappingItemTooltip } from '../mapping-item-tooltip';

import { PlayerAttributesContainer, ScheduleAttributesContainer } from './styles';

type Props = {
    entity: MappingEntity;
};

export const MappingItemAttributes = (props: Props) => {
    if (props.entity.type === MappingType.PLAYER) {
        return (
            <PlayerAttributesContainer>
                <MappingItemTooltip entity={props.entity}>
                    <span className="mapping-item-attributes-title">{props.entity.name}</span>
                </MappingItemTooltip>
                <span>{props.entity.jersey ? `#${props.entity.jersey}` : null}</span>
                <span>{props.entity.position}</span>
                <span>
                    {props.entity.source === SourceId.SR ? 'SRID: ' : 'ID: '}
                    {props.entity.id}
                </span>
            </PlayerAttributesContainer>
        );
    }

    if (props.entity.type === MappingType.SCHEDULE) {
        return (
            <ScheduleAttributesContainer>
                <MappingItemTooltip entity={props.entity}>
                    <span className="mapping-item-attributes-title">{props.entity.name}</span>
                </MappingItemTooltip>
                <span>{props.entity.date}</span>
                <span>
                    {props.entity.source === SourceId.SR ? 'SRID: ' : 'ID: '}
                    {props.entity.id}
                </span>
            </ScheduleAttributesContainer>
        );
    }

    return null;
};
