import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { GetTeamMappingsStatsParameters, TeamMappingsStatsResponse } from 'api/types/mapper';

import { MappingKeys } from './queries';

async function getTeamMappingsStats(
    parameters: GetTeamMappingsStatsParameters,
): Promise<TeamMappingsStatsResponse> {
    const url = '{base_url}/{league}/stats/{team_id}'
        .replace('{base_url}', API.MAPPINGS_API)
        .replace('{league}', parameters.league)
        .replace('{team_id}', parameters.teamId);

    return client.get(url).then((response) => response.data[parameters.league]);
}

type QueryOptions = {
    enabled?: boolean;
};

export function useTeamMappingsStatsQuery(
    parameters: GetTeamMappingsStatsParameters,
    options?: QueryOptions,
) {
    return useQuery({
        queryKey: MappingKeys.teamMappingsStats(parameters),
        queryFn: () => getTeamMappingsStats(parameters),
        onError: console.error,
        refetchOnWindowFocus: false,
        enabled: options?.enabled ?? true,
        staleTime: 60 * 60 * 1000, // 1h
        cacheTime: 60 * 60 * 1000, // 1h
    });
}
