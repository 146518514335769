import styled from '@emotion/styled';

const MappingItemAttributesContainer = styled.div`
    font-size: 0.75rem;
    font-weight: 700;

    & > span {
        display: flex;
        align-items: center;

        &:not(.mapping-item-attributes-title) {
            opacity: 0.6;
        }
    }

    .mapping-item-attributes-title {
        display: flex;
        align-items: center;
        column-gap: 0.25rem;
        opacity: 1;
    }
`;

export const PlayerAttributesContainer = styled(MappingItemAttributesContainer)`
    flex-grow: 1;
    display: grid;
    grid-template-columns: 8rem 2rem 2rem 1fr;
    align-self: stretch;
`;

export const ScheduleAttributesContainer = styled(MappingItemAttributesContainer)`
    flex-grow: 1;
    display: grid;
    grid-template-columns: 12rem 12rem 1fr;
    align-self: stretch;
`;
