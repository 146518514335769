import dayjs from 'dayjs';
import * as React from 'react';
import { Route } from 'type-route';

import { FlagsSortingAttribute } from 'api/types/bff';
import { DiscrepancyStatusFilter } from 'api/types/flags';
import { routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import ControlBar from 'components/control-bar';
import ControlPanel from 'components/control-panel';
import Dropdown from 'components/dropdown';
import FlagAccordion from 'components/flag-accordion';
import FlagStats from 'components/flag-stats';
import { IconDecorated } from 'components/icons';
import DateSelector, { DateSelectorValue } from 'components/primitives/date-selector';
import Tooltip from 'components/primitives/tooltip';
import View from 'components/view';
import ViewHeader from 'components/view-header';
import { useDiscrepancyStatusOptions } from 'hooks/options/useDiscrepancyStatusOptions';
import { useFlagsSortingOptions } from 'hooks/options/useFlagsSortingOptions';
import { useWeekOptions } from 'hooks/options/useWeekOptions';
import { useChangeParams } from 'hooks/useChangeParams';
import useDateSnapshot from 'hooks/useDateSnapshot';
import useFlags from 'hooks/useFlagStats/useFlags';
import useFlagStatsSummary from 'hooks/useFlagStats/useGetFlagStatsSummary';
import useGamesGroupingStrategyLabel from 'hooks/useGameGroupingLabel';
import { Sorting } from 'types/sorting';

import useLeagueInfo from '../../../../app/useLeagueInfo';
import Navigation from '../../components/navigation';

type Props = {
    route: Route<typeof routes.gameBoxscores>;
};

const BoxscoreAll = ({ route }: Props) => {
    const leagueDetails = useLeagueDetails(route.params.league);
    const leagueInfoQuery = useLeagueInfo({
        league: route.params.league,
        seasonType: route.params.seasonType,
        seasonYear: route.params.seasonYear,
    });
    const leagueGamesLabel = useGamesGroupingStrategyLabel(route.params.league);

    const statusOptions = useDiscrepancyStatusOptions(route.params.status);

    const weekOptions = useWeekOptions(leagueInfoQuery.data?.weeks ?? [], route.params.week);
    const dateFrom = route.params.dateFrom ?? dayjs();
    const dateTo = route.params.dateTo ?? dayjs();
    const dateValues: DateSelectorValue = dateFrom.isSame(dateTo) ? [dateFrom] : [dateFrom, dateTo];

    const sortingOptions = useFlagsSortingOptions(
        [FlagsSortingAttribute.DiscrepancyCount, FlagsSortingAttribute.DiscrepancyDate],
        { attribute: route.params.sortBy, order: route.params.sortOrder },
    );

    const flagsQuery = useFlags({
        league: route.params.league,
        seasonType: route.params.seasonType,
        seasonYear: route.params.seasonYear,
        flagType: 'boxscore',
        discrepancyStatus: route.params.status,
        sortBy: route.params.sortBy,
        sortOrder: route.params.sortOrder,
        ...(leagueDetails.filters.gameWeek && {
            week: route.params.week,
        }),
        ...(leagueDetails.filters.gameDate && {
            dateFrom: dateFrom.format('YYYY-MM-DD'),
            dateTo: dateTo.format('YYYY-MM-DD'),
        }),
    });
    const flagStatsSummary = useFlagStatsSummary();

    const updateDate = useDateSnapshot(flagsQuery.dataUpdatedAt);

    const handleParamsChange = useChangeParams(route);

    const handleDateChange = React.useCallback(
        (dates: DateSelectorValue) =>
            handleParamsChange({ dateFrom: dates[0], dateTo: dates[1] ?? dates[0] }),
        [handleParamsChange],
    );
    const handleWeekChange = React.useCallback(
        (week?: string) => handleParamsChange({ week }),
        [handleParamsChange],
    );
    const handleSortingChange = React.useCallback(
        (sorting: Sorting<FlagsSortingAttribute>) =>
            handleParamsChange({ sortBy: sorting.attribute, sortOrder: sorting.order }),
        [handleParamsChange],
    );
    const handleStatusChange = React.useCallback(
        (status: DiscrepancyStatusFilter) => handleParamsChange({ status }),
        [handleParamsChange],
    );

    return (
        <View route={route}>
            <ViewHeader
                iconElement={
                    <IconDecorated paletteColor="green500" sizeRem={1.5} name="WysiwygRounded" />
                }
                iconFormat="inline"
                titleText="Game Boxscores"
            >
                <ControlBar spacing="small">
                    <Tooltip title="Last time the flags were pulled from the system">
                        <ControlBar.Text>Updated {updateDate}</ControlBar.Text>
                    </Tooltip>
                    <Tooltip
                        disabled={flagsQuery.isFetching}
                        disableInteractive
                        title="Click to refresh the flags"
                    >
                        <ControlBar.Button
                            disabled={flagsQuery.isFetching}
                            onClick={() => flagsQuery.refetch()}
                        >
                            {flagsQuery.isFetching ? 'Refreshing...' : 'Refresh'}
                        </ControlBar.Button>
                    </Tooltip>
                </ControlBar>
            </ViewHeader>
            <Navigation gamesLabel={leagueGamesLabel} route={route} />
            <FlagStats
                flagged={flagStatsSummary.boxscore.created}
                ignored={flagStatsSummary.boxscore.ignored}
            />
            <ControlPanel>
                <ControlPanel.Group>
                    {leagueDetails.filters.gameDate && (
                        <DateSelector value={dateValues} onChange={handleDateChange} />
                    )}
                    {leagueDetails.filters.gameWeek && (
                        <Dropdown label="Week" options={weekOptions} onChange={handleWeekChange} />
                    )}
                    <Dropdown
                        label="Sort"
                        options={sortingOptions}
                        onChange={handleSortingChange}
                    />
                    <Dropdown
                        label="Status"
                        options={statusOptions}
                        onChange={handleStatusChange}
                    />
                </ControlPanel.Group>
            </ControlPanel>
            <FlagAccordion
                moduleHeader
                loading={flagsQuery.isLoading}
                flags={flagsQuery.data}
                status={route.params.status}
            />
        </View>
    );
};

export default BoxscoreAll;
