import dayjs from 'dayjs';
import * as typeRoute from 'type-route';

import { isFlagSortingAttribute, isTeamSorting, isTeamStatus } from 'api/types/bff';
import { isDiscrepancyStatusFilter } from 'api/types/flags';
import { isGameStatus } from 'api/types/leagues';
import { LiveFlags } from 'api/types/live-flags';
import { isMappingType } from 'api/types/mapper';
import { isExternalSourceId, isLeague, isSeasonType, isSeasonYear } from 'types';
import { isCoverage } from 'types/coverage';
import { isOrder } from 'types/sorting';

const serializer = <T extends string>(
    validate: (param: string) => param is T,
): typeRoute.ValueSerializer<T> => ({
    parse: (param) => (validate(param) ? param : typeRoute.noMatch),
    stringify: (value) => value,
});

export const league = serializer(isLeague);
export const seasonType = serializer(isSeasonType);
export const seasonYear = serializer(isSeasonYear);
export const externalSource = serializer(isExternalSourceId);
export const sortOrder = serializer(isOrder);
export const flagsDiscrepancyStatus = serializer(isDiscrepancyStatusFilter);
export const flagsSortBy = serializer(isFlagSortingAttribute);
export const gameStatus = serializer(isGameStatus);
export const gameCoverage = serializer(isCoverage);
export const teamStatus = serializer(isTeamStatus);
export const teamSorting = serializer(isTeamSorting);
export const teamMappingType = serializer(isMappingType);
export const liveFlagStatus = serializer(LiveFlags.isFlagStatus);
export const liveFlagCategoryName = serializer(LiveFlags.isLiveFlagCategoryName);

export const date: typeRoute.ValueSerializer<dayjs.Dayjs> = {
    parse: (param) => dayjs(param),
    stringify: (date) => date.format('YYYY-MM-DD'),
};
