import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const MappingDraggableContainer = styled.div<{ isDragging: boolean; hasActive: boolean }>`
    border-radius: 0.25rem;
    cursor: grab;
    transition: box-shadow 100ms;

    ${(props) =>
        !props.hasActive &&
        css`
            :hover {
                box-shadow: 0rem 0rem 0.25rem 0.05rem var(--color-borderline);
            }
        `}

    ${(props) =>
        props.isDragging &&
        css`
            background: var(--color-background);
            opacity: 0.5;
            box-shadow: 0rem 0rem 1rem 0.125rem var(--color-borderline);
        `}
`;
