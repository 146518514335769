import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import { MappingDraggableContainer } from './styles';

type Props = {
    children: React.ReactNode;
    id: string;
    data?: object;
};

export const MappingDraggable = (props: Props) => {
    const draggable = useDraggable({ id: props.id, data: props.data });

    return (
        <MappingDraggableContainer
            isDragging={draggable.isDragging}
            hasActive={!!draggable.active}
            ref={draggable.setNodeRef}
            style={{ transform: CSS.Translate.toString(draggable.transform) }}
            {...draggable.listeners}
            {...draggable.attributes}
        >
            {props.children}
        </MappingDraggableContainer>
    );
};
