import { css, Global, SerializedStyles } from '@emotion/react';

export type Color = { r: number; g: number; b: number; a: number };
export type ColorVar = string;

// prettier-ignore
type HexDigit = | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F';
type HexColor<T extends string> = T extends `#${HexDigit}${HexDigit}${HexDigit}${infer Rest}`
    ? Rest extends `${HexDigit}${HexDigit}${HexDigit}`
        ? T
        : never
    : never;
// HexColor type is equal to `#${HexDigit}${HexDigit}${HexDigit}${HexDigit}${HexDigit}${HexDigit}`, but
// `#${HexDigit}${HexDigit}${HexDigit}${HexDigit}${HexDigit}${HexDigit}` is to complex, so I needed to make workaround

export const hexWithA = <T extends string>(color: HexColor<T>, a: number): Color => ({
    r: parseInt((color as string).slice(1, 3), 16),
    g: parseInt((color as string).slice(3, 5), 16),
    b: parseInt((color as string).slice(5, 7), 16),
    a,
});

export const colorWithAlpha = (color: Color, alpha: number): Color => ({
    ...color,
    a: alpha,
});

export const hex = <T extends string>(color: HexColor<T>): Color => hexWithA(color, 1);

export const colours = {
    white: hex('#FFFFFF'),
    black100: hex('#F3F4F5'),
    black200: hex('#DCDEE2'),
    black300: hex('#88919C'),
    black400: hex('#596575'),
    black500: hex('#12233A'),
    black700: hex('#00003C'),
    blue100: hex('#E6F9FF'),
    blue200: hex('#C9ECFF'),
    blue300: hex('#85D3FF'),
    blue400: hex('#7FB3F0'),
    blue500: hex('#0068E2'),
    blue600: hex('#08458D'),
    blue700: hex('#0C376C'),
    red100: hex('#FFF2F2'),
    red200: hex('#FFCCCC'),
    red500: hex('#FF0000'),
    red600: hex('#D0021B'),
    red700: hex('#4A1930'),
    yellow100: hex('#FEFAD2'),
    yellow200: hex('#FFF1DA'),
    yellow500: hex('#F8E71C'),
    yellow600: hex('#FFBA4B'),
    green100: hex('#EBF9E8'),
    green500: hex('#3FC521'),
    green800: hexWithA('#3FC521', 0.2), // This is random name
    gray100: hex('#F7F9FB'),
    gray300: hex('#DAE4ED'),
    gray700: hex('#B2C6D9'),
    gray500: hex('#19304E'),
    purple: hex('#B288FF'), // This is random name
    blue1000: hex('#74BFD7'), // This is random name
    blue2000: hexWithA('#2A74D9', 0.1), // This is random name
};

export type ColorScheme = {
    '--color-background': Color;
    '--color-text': Color;
    '--color-text-link': Color;
    '--color-icon': Color;
    '--color-component-background': Color;
    '--color-borderline': Color;
    '--color-borderline-secondary': Color;
    '--color-button': Color;
    '--color-go-back-button': Color;
    '--color-action-button-variant-default-border': Color;
    '--color-action-button-variant-default-icon': Color;
    '--color-action-button-variant-default-text': Color;
    '--color-action-button-variant-primary-border': Color;
    '--color-action-button-variant-primary-icon': Color;
    '--color-action-button-variant-primary-text': Color;
    '--color-modal-action-background': Color;
    '--color-highlight': Color;
    '--color-highlight-fault': Color;
    '--color-box-borderline': Color;
    '--color-box-text': Color;
    '--color-box-default-borderline': Color;
    '--color-box-arrow-up': Color;
    '--color-box-arrow-down': Color;
    '--color-box-arrow-text': Color;
    '--color-active-tab': Color;
    '--color-inactive-tab': Color;
    '--color-modal-background': Color;
    '--color-modal-content-background': Color;
    '--color-dialog-actions-background': Color;
    '--color-theme-switch-icon-active': Color;
    '--color-container-background-color': Color;
    '--color-sidebar-title': Color;
    '--color-sidebar-hover-bg': Color;
    '--color-flags-accordion-borderline': Color;
    '--color-flags-accordion-source-bg': Color;
    '--color-flags-accordion-source-border-color': Color;
    '--color-flags-accordion-source-active-bg-color': Color;
    '--color-flags-accordion-source-active-border-color': Color;
    '--color-flags-accordion-source-amount': Color;
    '--color-flag-team-images-divider': Color;
    '--color-dropdown-border': Color;
    '--color-interactive-bg-hover': Color;
    '--color-discrepancy-category-title-color': Color;
    '--color-discrepancy-category-title-bgcolor': Color;
    '--color-discrepancy-source-active-bgcolor': Color;
    '--color-discrepancy-source-count': Color;
    '--color-flagged-discrepancy-count': Color;
    '--color-ignored-discrepancy-count': Color;
    '--color-flag-stats-box-shadow': Color;
    '--color-flag-stats-box-border': Color;
    '--color-flag-stats-refresh-border': Color;
    '--color-flag-stats-refresh-color': Color;
    '--color-live-game-discrepancy-item-border': Color;
    '--color-live-game-discrepancy-item-status-created-text': Color;
    '--color-live-game-discrepancy-item-status-ignored-background': Color;
    '--color-live-game-discrepancy-item-status-ignored-text': Color;
    '--color-live-game-discrepancy-item-status-resolved-text': Color;
    '--color-live-game-discrepancy-issue-background': Color;
    '--color-live-game-discrepancy-issue-status-resolved-background': Color;
    '--color-live-game-divider': Color;
    '--color-live-game-no-flags-label': Color;
    '--color-direct-links-form-title': Color;
    '--color-direct-links-form-url-label': Color;
    '--color-direct-links-form-url-border': Color;
    '--color-direct-links-form-url-text': Color;
    '--color-direct-links-form-button-label': Color;
    '--color-direct-links-form-button-border': Color;
    '--color-direct-links-form-background': Color;
    '--color-game-card-score-bg-has-discrepancy': Color;
    '--color-game-card-score-bg': Color;
    '--color-btn-dark-primary-border': Color;
    '--color-btn-dark-primary-text': Color;
    '--color-btn-dark-primary-bg': Color;
    '--color-btn-variant5-border': Color;
    '--color-btn-variant5-text': Color;
    '--color-btn-variant5-bg': Color;
    '--color-home-league-border': Color;
    '--color-accordion-bg': Color;
    '--color-mapping-links': Color;
    '--color-mapping-links-off': Color;
    '--color-mapping-dialog-button': Color;
    '--color-mapping-attributes-card-unmapping-bg': Color;
    '--color-mapping-attributes-card-unmapping-border': Color;
    '--color-mapping-attributes-card-unmapping-color': Color;
    '--color-mapping-attributes-card-unmapping-entity-bg': Color;
    '--color-mapping-attributes-card-unmapping-entity-border': Color;
    '--color-mapping-attributes-card-unmapping-entity-color': Color;
    '--color-mapping-attributes-card-unmapping-label-color': Color;
    '--color-mapping-attributes-card-remapping-bg': Color;
    '--color-mapping-attributes-card-remapping-border': Color;
    '--color-mapping-attributes-card-remapping-color': Color;
    '--color-mapping-attributes-card-remapping-entity-bg': Color;
    '--color-mapping-attributes-card-remapping-entity-border': Color;
    '--color-mapping-attributes-card-remapping-entity-color': Color;
    '--color-mapping-attributes-card-remapping-label-color': Color;
    '--color-mapping-attributes-card-mapping-bg': Color;
    '--color-mapping-attributes-card-mapping-border': Color;
    '--color-mapping-attributes-card-mapping-color': Color;
    '--color-mapping-attributes-card-mapping-entity-bg': Color;
    '--color-mapping-attributes-card-mapping-entity-border': Color;
    '--color-mapping-attributes-card-mapping-entity-color': Color;
    '--color-mapping-attributes-card-mapping-label-color': Color;
    '--color-mapping-attributes-card-selecting-bg': Color;
    '--color-mapping-attributes-card-selecting-border': Color;
    '--color-mapping-attributes-card-selecting-color': Color;
    '--color-mapping-attributes-card-selecting-entity-bg': Color;
    '--color-mapping-attributes-card-selecting-entity-border': Color;
    '--color-mapping-attributes-card-selecting-entity-color': Color;
    '--color-mapping-attributes-card-selected-bg': Color;
    '--color-mapping-attributes-card-selected-border': Color;
    '--color-mapping-attributes-card-selected-color': Color;
    '--color-mapping-attributes-card-selected-entity-bg': Color;
    '--color-mapping-attributes-card-selected-entity-border': Color;
    '--color-mapping-attributes-card-selected-entity-color': Color;
    '--color-mapping-attributes-card-selected-label-color': Color;
};

const lightScheme: ColorScheme = {
    '--color-background': colours.white,
    '--color-text': colours.black500,
    '--color-text-link': colours.blue500,
    '--color-icon': colours.gray700,
    '--color-component-background': hexWithA('#000000', 0), //transparent
    '--color-borderline': colours.black200,
    '--color-borderline-secondary': colours.gray300,
    '--color-button': colours.blue500,
    '--color-go-back-button': colours.blue500,
    '--color-action-button-variant-default-border': colours.gray300,
    '--color-action-button-variant-default-icon': colours.black500,
    '--color-action-button-variant-default-text': colours.black500,
    '--color-action-button-variant-primary-border': colours.gray700,
    '--color-action-button-variant-primary-icon': colours.black300,
    '--color-modal-action-background': colours.gray100,
    '--color-highlight': colours.blue100,
    '--color-highlight-fault': colours.red100,
    '--color-box-borderline': colours.red600,
    '--color-box-text': colours.black400,
    '--color-box-default-borderline': colours.black200,
    '--color-box-arrow-up': colours.green500,
    '--color-box-arrow-down': colours.black300,
    '--color-box-arrow-text': colours.black300,
    '--color-active-tab': colours.black500,
    '--color-inactive-tab': colours.black300,
    '--color-modal-background': hexWithA('#12233A', 0.8),
    '--color-modal-content-background': colours.white,
    '--color-dialog-actions-background': colours.gray100,
    '--color-theme-switch-icon-active': colours.yellow600,
    '--color-container-background-color': hex('#FAFBFD'),
    '--color-sidebar-title': colours.black500,
    '--color-sidebar-hover-bg': colours.gray300,
    '--color-flags-accordion-borderline': colours.gray300,
    '--color-flags-accordion-source-bg': hexWithA('#000000', 0), // transparent,
    '--color-flags-accordion-source-border-color': colours.gray500,
    '--color-flags-accordion-source-active-bg-color': colorWithAlpha(colours.blue500, 0.1),
    '--color-flags-accordion-source-active-border-color': colours.blue500,
    '--color-flags-accordion-source-amount': colours.red500,
    '--color-flag-team-images-divider': colorWithAlpha(colours.black300, 0.6),
    '--color-dropdown-border': colours.gray300,
    '--color-interactive-bg-hover': colours.black100,
    '--color-discrepancy-category-title-color': colours.black500,
    '--color-discrepancy-category-title-bgcolor': colours.gray100,
    '--color-discrepancy-source-active-bgcolor': colorWithAlpha(colours.blue500, 0.05),
    '--color-discrepancy-source-count': colours.red600,
    '--color-flagged-discrepancy-count': colours.red600,
    '--color-ignored-discrepancy-count': colours.black300,
    '--color-flag-stats-box-shadow': colorWithAlpha(colours.blue2000, 0.1),
    '--color-flag-stats-box-border': colorWithAlpha(colours.black500, 0.1),
    '--color-flag-stats-refresh-border': colours.gray300,
    '--color-flag-stats-refresh-color': colours.black500,
    '--color-action-button-variant-primary-text': colorWithAlpha(colours.gray500, 0.5),
    '--color-live-game-discrepancy-item-border': colours.gray300,
    '--color-live-game-discrepancy-item-status-created-text': colours.black500,
    '--color-live-game-discrepancy-item-status-ignored-background': colours.gray100,
    '--color-live-game-discrepancy-item-status-ignored-text': colours.black300,
    '--color-live-game-discrepancy-item-status-resolved-text': colours.black300,
    '--color-live-game-discrepancy-issue-background': colorWithAlpha(colours.red600, 0.1),
    '--color-live-game-discrepancy-issue-status-resolved-background': colours.green100,
    '--color-live-game-divider': colours.gray700,
    '--color-live-game-no-flags-label': colorWithAlpha(colours.black500, 0.5),
    '--color-direct-links-form-title': colours.black500,
    '--color-direct-links-form-url-label': colours.black500,
    '--color-direct-links-form-url-border': colours.gray300,
    '--color-direct-links-form-url-text': colours.black500,
    '--color-direct-links-form-button-label': colours.blue500,
    '--color-direct-links-form-button-border': colours.blue500,
    '--color-direct-links-form-background': colours.gray300,
    '--color-game-card-score-bg': hexWithA('#000000', 0), //transparent
    '--color-game-card-score-bg-has-discrepancy': colours.red200,
    '--color-btn-dark-primary-border': colours.gray700,
    '--color-btn-dark-primary-text': colours.blue500,
    '--color-btn-dark-primary-bg': colours.white,
    '--color-btn-variant5-border': colours.blue500,
    '--color-btn-variant5-text': colours.blue500,
    '--color-btn-variant5-bg': colours.white,
    '--color-home-league-border': colours.gray300,
    '--color-accordion-bg': colours.white,
    '--color-mapping-links': colours.green500,
    '--color-mapping-links-off': colours.red500,
    '--color-mapping-dialog-button': colours.blue500,
    '--color-mapping-attributes-card-unmapping-bg': colours.red100,
    '--color-mapping-attributes-card-unmapping-border': colours.red100,
    '--color-mapping-attributes-card-unmapping-color': colours.black500,
    '--color-mapping-attributes-card-unmapping-entity-bg': colours.red200,
    '--color-mapping-attributes-card-unmapping-entity-border': colours.red200,
    '--color-mapping-attributes-card-unmapping-entity-color': colours.black500,
    '--color-mapping-attributes-card-unmapping-label-color': colours.black500,
    '--color-mapping-attributes-card-remapping-bg': colours.yellow100,
    '--color-mapping-attributes-card-remapping-border': colours.yellow100,
    '--color-mapping-attributes-card-remapping-color': colours.black500,
    '--color-mapping-attributes-card-remapping-entity-bg': colours.yellow500,
    '--color-mapping-attributes-card-remapping-entity-border': colours.yellow500,
    '--color-mapping-attributes-card-remapping-entity-color': colours.black500,
    '--color-mapping-attributes-card-remapping-label-color': colours.black400,
    '--color-mapping-attributes-card-mapping-bg': colours.gray100,
    '--color-mapping-attributes-card-mapping-border': colours.gray100,
    '--color-mapping-attributes-card-mapping-color': colours.black500,
    '--color-mapping-attributes-card-mapping-entity-bg': colours.gray100,
    '--color-mapping-attributes-card-mapping-entity-border': colours.black200,
    '--color-mapping-attributes-card-mapping-entity-color': colours.black500,
    '--color-mapping-attributes-card-mapping-label-color': colours.black400,
    '--color-mapping-attributes-card-selecting-bg': colours.white,
    '--color-mapping-attributes-card-selecting-border': colours.gray300,
    '--color-mapping-attributes-card-selecting-color': colours.black500,
    '--color-mapping-attributes-card-selecting-entity-bg': colours.white,
    '--color-mapping-attributes-card-selecting-entity-border': colours.gray300,
    '--color-mapping-attributes-card-selecting-entity-color': colours.black500,
    '--color-mapping-attributes-card-selected-bg': colours.green100,
    '--color-mapping-attributes-card-selected-border': colours.green100,
    '--color-mapping-attributes-card-selected-color': colours.black500,
    '--color-mapping-attributes-card-selected-entity-bg': colours.white,
    '--color-mapping-attributes-card-selected-entity-border': colours.black200,
    '--color-mapping-attributes-card-selected-entity-color': colours.black500,
    '--color-mapping-attributes-card-selected-label-color': colours.black400,
};

const darkScheme: ColorScheme = {
    '--color-background': colours.black500,
    '--color-text': colours.blue100,
    '--color-text-link': colours.blue300,
    '--color-icon': colours.blue200,
    '--color-component-background': hexWithA('#000000', 0), //transparent
    '--color-borderline': colours.blue500,
    '--color-borderline-secondary': colorWithAlpha(colours.gray700, 0.48),
    '--color-button': colours.blue500,
    '--color-action-button-variant-default-border': colours.blue100,
    '--color-action-button-variant-default-icon': colours.blue100,
    '--color-action-button-variant-default-text': colours.blue100,
    '--color-action-button-variant-primary-border': colours.blue500,
    '--color-action-button-variant-primary-icon': colours.blue100,
    '--color-action-button-variant-primary-text': colorWithAlpha(colours.blue100, 0.5),
    '--color-go-back-button': colours.blue100,
    '--color-modal-action-background': colours.blue500,
    '--color-highlight': colours.blue500,
    '--color-highlight-fault': colours.red700,
    '--color-box-borderline': colours.red600,
    '--color-box-text': colours.blue100,
    '--color-box-default-borderline': colours.black400,
    '--color-box-arrow-up': colours.blue200,
    '--color-box-arrow-down': colours.blue200,
    '--color-box-arrow-text': colours.blue200,
    '--color-active-tab': colours.blue500,
    '--color-inactive-tab': colours.blue600,
    '--color-modal-background': hexWithA('#DCDEE2', 0.1),
    '--color-modal-content-background': colours.black500,
    '--color-dialog-actions-background': colours.gray500,
    '--color-theme-switch-icon-active': colours.blue500,
    '--color-container-background-color': colours.black500,
    '--color-sidebar-title': colours.blue100,
    '--color-sidebar-hover-bg': colours.blue700,
    '--color-flags-accordion-borderline': colorWithAlpha(colours.blue500, 0.5),
    '--color-flags-accordion-source-bg': hexWithA('#000000', 0),
    '--color-flags-accordion-source-border-color': colours.gray300,
    '--color-flags-accordion-source-active-bg-color': colours.blue500,
    '--color-flags-accordion-source-active-border-color': colours.blue500,
    '--color-flags-accordion-source-amount': colours.red200,
    '--color-flag-team-images-divider': colours.gray700,
    '--color-dropdown-border': colours.blue500,
    '--color-interactive-bg-hover': colours.gray500,
    '--color-discrepancy-category-title-color': colours.blue100,
    '--color-discrepancy-category-title-bgcolor': colours.blue700,
    '--color-discrepancy-source-active-bgcolor': colours.blue500,
    '--color-discrepancy-source-count': colours.red200,
    '--color-flagged-discrepancy-count': colours.red200,
    '--color-ignored-discrepancy-count': colours.black300,
    '--color-flag-stats-box-shadow': colours.blue2000,
    '--color-flag-stats-box-border': colours.blue500,
    '--color-flag-stats-refresh-border': colours.blue500,
    '--color-flag-stats-refresh-color': colours.blue100,
    '--color-live-game-discrepancy-item-border': colours.gray300,
    '--color-live-game-discrepancy-item-status-created-text': colours.blue100,
    '--color-live-game-discrepancy-item-status-ignored-background': colours.black300,
    '--color-live-game-discrepancy-item-status-ignored-text': colours.black100,
    '--color-live-game-discrepancy-item-status-resolved-text': colours.black300,
    '--color-live-game-discrepancy-issue-background': colorWithAlpha(colours.red600, 0.2),
    '--color-live-game-discrepancy-issue-status-resolved-background': colours.green100,
    '--color-live-game-divider': colours.blue500,
    '--color-live-game-no-flags-label': colorWithAlpha(colours.blue100, 0.5),
    '--color-direct-links-form-title': colours.blue100,
    '--color-direct-links-form-url-label': colours.blue100,
    '--color-direct-links-form-url-border': colours.blue100,
    '--color-direct-links-form-url-text': colours.blue100,
    '--color-direct-links-form-button-label': colours.blue100,
    '--color-direct-links-form-button-border': colours.blue100,
    '--color-direct-links-form-background': colours.black500,
    '--color-game-card-score-bg': colours.blue700,
    '--color-game-card-score-bg-has-discrepancy': colours.red600,
    '--color-btn-dark-primary-border': colours.blue300,
    '--color-btn-dark-primary-text': colours.blue300,
    '--color-btn-dark-primary-bg': colorWithAlpha(colours.blue300, 0),
    '--color-btn-variant5-border': colours.blue500,
    '--color-btn-variant5-text': colours.blue300,
    '--color-btn-variant5-bg': colorWithAlpha(colours.blue300, 0),
    '--color-home-league-border': colorWithAlpha(colours.blue500, 0.5),
    '--color-accordion-bg': colours.blue700,
    '--color-mapping-links': colours.green500,
    '--color-mapping-links-off': colours.red500,
    '--color-mapping-dialog-button': colours.blue500,
    '--color-mapping-attributes-card-unmapping-bg': colours.gray500,
    '--color-mapping-attributes-card-unmapping-border': colours.gray500,
    '--color-mapping-attributes-card-unmapping-color': colours.blue100,
    '--color-mapping-attributes-card-unmapping-entity-bg': colours.red200,
    '--color-mapping-attributes-card-unmapping-entity-border': colours.red200,
    '--color-mapping-attributes-card-unmapping-entity-color': colours.gray500,
    '--color-mapping-attributes-card-unmapping-label-color': colours.blue200,
    '--color-mapping-attributes-card-remapping-bg': colours.gray500,
    '--color-mapping-attributes-card-remapping-border': colours.gray500,
    '--color-mapping-attributes-card-remapping-color': colours.blue100,
    '--color-mapping-attributes-card-remapping-entity-bg': colours.yellow200,
    '--color-mapping-attributes-card-remapping-entity-border': colours.yellow200,
    '--color-mapping-attributes-card-remapping-entity-color': colours.black500,
    '--color-mapping-attributes-card-remapping-label-color': colours.blue200,
    '--color-mapping-attributes-card-mapping-bg': colours.gray500,
    '--color-mapping-attributes-card-mapping-border': colours.gray500,
    '--color-mapping-attributes-card-mapping-color': colours.blue100,
    '--color-mapping-attributes-card-mapping-entity-bg': colours.gray500,
    '--color-mapping-attributes-card-mapping-entity-border': colours.black200,
    '--color-mapping-attributes-card-mapping-entity-color': colours.blue100,
    '--color-mapping-attributes-card-mapping-label-color': colours.blue200,
    '--color-mapping-attributes-card-selecting-bg': colours.black500,
    '--color-mapping-attributes-card-selecting-border': colours.black200,
    '--color-mapping-attributes-card-selecting-color': colours.blue100,
    '--color-mapping-attributes-card-selecting-entity-bg': colours.black500,
    '--color-mapping-attributes-card-selecting-entity-border': colours.black200,
    '--color-mapping-attributes-card-selecting-entity-color': colours.blue100,
    '--color-mapping-attributes-card-selected-bg': colours.green100,
    '--color-mapping-attributes-card-selected-border': colours.green100,
    '--color-mapping-attributes-card-selected-color': colours.black500,
    '--color-mapping-attributes-card-selected-entity-bg': colours.white,
    '--color-mapping-attributes-card-selected-entity-border': colours.gray500,
    '--color-mapping-attributes-card-selected-entity-color': colours.black500,
    '--color-mapping-attributes-card-selected-label-color': colours.black400,
};

const makeColorVariables = ({ r, g, b, a }: Color, variableBaseName: string): SerializedStyles =>
    css(`
    ${variableBaseName}: rgba(${r}, ${g}, ${b}, ${a});
    ${variableBaseName}-rgb: ${r}, ${g}, ${b};
`);

export const makeColorStyles = (scheme: ColorScheme): SerializedStyles => css`
    :root {
        ${Object.entries(scheme).map(([variableName, colour]) =>
            makeColorVariables(colour, variableName),
        )}
        ${Object.entries(colours).map(([variableName, colour]) =>
            makeColorVariables(colour, `--${variableName}`),
        )}
        --default-box-shadow: 0 0.125rem 0.25rem 0 rgba(42,116,217,0.12);
    }
`;

export const GlobalColorScheme = () => (
    <Global
        styles={css`
            ${makeColorStyles(lightScheme)};

            @media (prefers-color-scheme: dark) {
                ${makeColorStyles(darkScheme)}
            }

            :root[data-color-scheme-config='dark'] {
                ${makeColorStyles(darkScheme)}
            }

            :root[data-color-scheme-config='light'] {
                ${makeColorStyles(lightScheme)}
            }
        `}
    />
);
