import { FlagType, GamesGroupingStrategy, LeagueDetails, SeasonType, SourceId } from 'types';

const leaguesDetails: LeagueDetails[] = [
    {
        league: 'nfl',
        name: `National Football League`,
        nameAbbreviation: 'NFL',
        gamesGroupingStrategy: GamesGroupingStrategy.WEEKLY,
        sources: [SourceId.NFLGSIS, SourceId.TeamSites, SourceId.ESPN],
        supportedLiveGameSources: [SourceId.NFLGSIS, SourceId.ESPN],
        mappingSiteSources: [SourceId.NFLGSIS, SourceId.TeamSites, SourceId.ESPN],
        seasons: [
            {
                seasonYear: '2023',
                seasonTypes: [
                    SeasonType.PreSeason,
                    SeasonType.Regular,
                    SeasonType.PostSeasonTournaments,
                ],
            },
            {
                seasonYear: '2024',
                seasonTypes: [
                    SeasonType.PreSeason,
                    SeasonType.Regular,
                    SeasonType.PostSeasonTournaments,
                ],
            },
        ],
        flagTypes: [FlagType.Boxscore, FlagType.Roster, FlagType.Schedule, FlagType.Seasonal],
        filters: {
            gameDate: false,
            gameWeek: true,
            leagueHierarchy: false,
        },
    },
    {
        league: 'cfb',
        name: `NCAA Men's Football`,
        nameAbbreviation: 'CFB',
        gamesGroupingStrategy: GamesGroupingStrategy.WEEKLY,
        sources: [
            SourceId.NCAA,
            SourceId.ESPN,
            SourceId.AWAY,
            SourceId.HOME,
            SourceId.WMT,
            SourceId.Statbroadcast,
        ],
        supportedLiveGameSources: [SourceId.ESPN, SourceId.NCAA, SourceId.Statbroadcast],
        mappingSiteSources: [
            SourceId.NCAA,
            SourceId.ESPN,
            SourceId.OWN,
            SourceId.OPP,
            SourceId.WMT,
            SourceId.Statbroadcast,
        ],
        seasons: [
            {
                seasonYear: '2022',
                seasonTypes: [SeasonType.Regular],
            },
            {
                seasonYear: '2023',
                seasonTypes: [SeasonType.Regular],
            },
            {
                isCurrent: true,
                seasonYear: '2024',
                seasonTypes: [SeasonType.Regular],
            },
        ],
        flagTypes: [
            FlagType.Boxscore,
            FlagType.Roster,
            FlagType.Schedule,
            FlagType.Seasonal,
            FlagType.Standings,
            FlagType.Polls,
        ],
        teamPageDefaultDivision: 'FBS',
        filters: {
            gameDate: false,
            gameWeek: true,
            leagueHierarchy: true,
        },
    },
    {
        league: 'ncaamb',
        name: `NCAA Men's Basketball`,
        nameAbbreviation: 'NCAAMB',
        gamesGroupingStrategy: GamesGroupingStrategy.DAILY,
        sources: [
            SourceId.NCAA,
            SourceId.ESPN,
            SourceId.AWAY,
            SourceId.HOME,
            SourceId.WMT,
            SourceId.Statbroadcast,
        ],
        supportedLiveGameSources: [SourceId.ESPN, SourceId.NCAA, SourceId.Statbroadcast],
        mappingSiteSources: [
            SourceId.NCAA,
            SourceId.ESPN,
            SourceId.OWN,
            SourceId.OPP,
            SourceId.WMT,
            SourceId.Statbroadcast,
        ],
        seasons: [
            {
                seasonYear: '2023',
                seasonTypes: [
                    SeasonType.Regular,
                    SeasonType.ConferenceTournaments,
                    SeasonType.PostSeasonTournaments,
                ],
            },
            {
                seasonYear: '2024',
                seasonTypes: [
                    SeasonType.Regular,
                    SeasonType.ConferenceTournaments,
                    SeasonType.PostSeasonTournaments,
                ],
            },
        ],
        flagTypes: [FlagType.Boxscore, FlagType.Roster, FlagType.Schedule, FlagType.Seasonal],
        teamPageDefaultDivision: 'D1',
        filters: {
            gameDate: true,
            gameWeek: false,
            leagueHierarchy: true,
        },
    },
    {
        league: 'ncaawb',
        name: `NCAA Women's Basketball`,
        nameAbbreviation: 'NCAAWB',
        gamesGroupingStrategy: GamesGroupingStrategy.DAILY,
        sources: [
            SourceId.NCAA,
            SourceId.ESPN,
            SourceId.AWAY,
            SourceId.HOME,
            SourceId.WMT,
            SourceId.Statbroadcast,
        ],
        supportedLiveGameSources: [SourceId.ESPN, SourceId.NCAA, SourceId.Statbroadcast],
        mappingSiteSources: [
            SourceId.NCAA,
            SourceId.ESPN,
            SourceId.OWN,
            SourceId.OPP,
            SourceId.WMT,
            SourceId.Statbroadcast,
        ],
        seasons: [
            {
                seasonYear: '2023',
                seasonTypes: [
                    SeasonType.ConferenceTournaments,
                    SeasonType.Regular,
                    SeasonType.PostSeasonTournaments,
                ],
            },
            {
                seasonYear: '2024',
                seasonTypes: [
                    SeasonType.Regular,
                    SeasonType.ConferenceTournaments,
                    SeasonType.PostSeasonTournaments,
                ],
            },
        ],
        flagTypes: [FlagType.Boxscore, FlagType.Roster, FlagType.Schedule, FlagType.Seasonal],
        teamPageDefaultDivision: 'D1-W',
        filters: {
            gameDate: true,
            gameWeek: false,
            leagueHierarchy: true,
        },
    },
];

export default leaguesDetails;
