import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { DropdownPlaceholder } from 'components/league-dropdown/styles';
import { PopoverContainer } from 'components/popover/styles';

export const Card = styled.div`
    .card-title {
        margin: 0 0 1.5rem 0;
        font-size: 1.125rem;
        font-weight: 700;
        color: var(--color-text);
    }

    .card-title-type {
        text-transform: capitalize;
    }

    .card-content {
        display: flex;
        gap: 1rem;
    }

    .card-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
        margin-top: 2rem;

        button {
            color: var(--color-mapping-dialog-button);
            text-transform: capitalize;

            &[type='submit'] {
                border-color: var(--color-mapping-dialog-button);
            }
        }
    }
`;

export const DropdownStyles = css`
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    border: 0.0625rem solid var(--color-mapping-attributes-card-mapping-entity-border);
`;

type AttributesCardVariant =
    | 'remapping'
    | 'unmapping'
    | 'mapping'
    | 'mapping-selecting'
    | 'mapping-selected';

function setupAttributesCardVariables(variant: AttributesCardVariant) {
    switch (variant) {
        case 'unmapping':
            return css`
                --container-bg: var(--color-mapping-attributes-card-unmapping-bg);
                --container-border: var(--color-mapping-attributes-card-unmapping-border);
                --container-color: var(--color-mapping-attributes-card-unmapping-color);
                --entity-bg: var(--color-mapping-attributes-card-unmapping-entity-bg);
                --entity-border: var(--color-mapping-attributes-card-unmapping-entity-border);
                --entity-color: var(--color-mapping-attributes-card-unmapping-entity-color);
                --label-color: var(--color-mapping-attributes-card-unmapping-label-color);
            `;
        case 'remapping':
            return css`
                --container-bg: var(--color-mapping-attributes-card-remapping-bg);
                --container-border: var(--color-mapping-attributes-card-remapping-border);
                --container-color: var(--color-mapping-attributes-card-remapping-color);
                --entity-bg: var(--color-mapping-attributes-card-remapping-entity-bg);
                --entity-border: var(--color-mapping-attributes-card-remapping-entity-border);
                --entity-color: var(--color-mapping-attributes-card-remapping-entity-color);
                --label-color: var(--color-mapping-attributes-card-remapping-label-color);
            `;
        case 'mapping':
            return css`
                --container-bg: var(--color-mapping-attributes-card-mapping-bg);
                --container-border: var(--color-mapping-attributes-card-mapping-border);
                --container-color: var(--color-mapping-attributes-card-mapping-color);
                --entity-bg: var(--color-mapping-attributes-card-mapping-entity-bg);
                --entity-border: var(--color-mapping-attributes-card-mapping-entity-border);
                --entity-color: var(--color-mapping-attributes-card-mapping-entity-color);
            `;
        case 'mapping-selecting':
            return css`
                --container-bg: var(--color-mapping-attributes-card-selecting-bg);
                --container-border: var(--color-mapping-attributes-card-selecting-border);
                --container-color: var(--color-mapping-attributes-card-selecting-color);
                --entity-bg: var(--color-mapping-attributes-card-selecting-entity-bg);
                --entity-border: var(--color-mapping-attributes-card-selecting-entity-border);
                --entity-color: var(--color-mapping-attributes-card-selecting-entity-color);
                --label-color: var(--color-mapping-attributes-card-selecting-label-color);
            `;
        case 'mapping-selected':
            return css`
                --container-bg: var(--color-mapping-attributes-card-selected-bg);
                --container-border: var(--color-mapping-attributes-card-selected-border);
                --container-color: var(--color-mapping-attributes-card-selected-color);
                --entity-bg: var(--color-mapping-attributes-card-selected-entity-bg);
                --entity-border: var(--color-mapping-attributes-card-selected-entity-border);
                --entity-color: var(--color-mapping-attributes-card-selected-entity-color);
                --label-color: var(--color-mapping-attributes-card-selected-label-color);
            `;
        default:
            return ``;
    }
}

export const AttributesCard = styled.div<{
    variant: AttributesCardVariant;
}>`
    ${(props) => setupAttributesCardVariables(props.variant)}

    min-width: 24.125rem;
    padding: 1.25rem 1.5rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid var(--container-border);
    background-color: var(--container-bg);
    color: var(--container-color);

    .attributes-card-title {
        margin: 0 0 0.5rem 0;
        font-size: 0.625rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    .attributes-card-entity-container {
        margin-bottom: 1rem;

        & > * {
            width: 100%;
        }
    }

    .attributes-card-entity {
        padding: 0.4375rem;
    }

    .attributes-card-entity,
    ${PopoverContainer} {
        width: 100%;
        border-radius: 0.25rem;
        border: 0.0625rem solid var(--entity-border);
        background-color: var(--entity-bg);
        color: var(--entity-color);
        font-size: 0.875rem;
        font-weight: 700;

        & > *,
        ${DropdownPlaceholder} {
            border: none;
            color: var(--entity-color);
            font-size: 0.875rem;
        }
    }

    .attributes-card-attributes {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 1rem;
        row-gap: 0.5rem;
        font-size: 0.875rem;
    }

    .attributes-card-label {
        color: var(--label-color);
    }
`;
