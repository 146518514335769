import { QueryClient } from 'react-query';

import { Game } from 'api/types/bff';

import { LiveFlags } from '../../api/types/live-flags';
import { GameKeys } from '../../modules/game-boxscores/views/game/hooks/queries';
import { WSBackendEvent } from '../types';

export enum SystemEventTopicTypes {
    FLAGS_UPDATE = 'FLAGS_UPDATE',
}

export const liveFlagsProcessor = async (
    queryClient: QueryClient,
    payload: object,
): Promise<void> => {
    const liveFlagMessage = payload as WSBackendEvent<LiveFlags.LiveFlagWSUpdateEvent>;

    if (liveFlagMessage.type !== SystemEventTopicTypes.FLAGS_UPDATE) {
        console.warn('liveFlagsProcessor: unknown message type: %s', liveFlagMessage.type);

        return;
    }

    const pk = liveFlagMessage.payload.toDelete.pk;

    let [league, , , id] = pk.split('#');

    // remove obsolete flags
    queryClient.setQueriesData<Game.LiveGame | undefined>(
        GameKeys.gameShort(league, id),
        (oldData) => {
            if (!oldData) {
                return oldData;
            }

            const toRemoveIds = liveFlagMessage.payload.toDelete.sk;

            return {
                ...oldData,
                liveFlags: oldData.liveFlags.filter((lf) => !toRemoveIds.includes(lf.id)),
            };
        },
    );

    // get active and stale queries to update
    const queriesData = queryClient.getQueriesData<Game.LiveGameFlag>(
        GameKeys.gameShort(league, id),
    );

    for (const queryData of queriesData) {
        const [queryKey] = queryData;

        const [, , gameId, status, source, context, statName] = Array.from(queryKey);
        // match the queries with WS flags

        queryClient.setQueryData<Game.LiveGame | undefined>(queryKey, (oldData) => {
            if (!oldData) {
                return oldData;
            }

            let liveFlags = [...oldData.liveFlags];

            for (const flag of liveFlagMessage.payload.toPut) {
                if (
                    flag.contextSrIds.game === gameId &&
                    flag.source === source &&
                    flag.status === status &&
                    (flag.context === String(context).replace('individual', 'player') ||
                        context === 'all') &&
                    (flag.name === statName || statName === 'all')
                ) {
                    // flag can be upserted because it matches the query
                    liveFlags = liveFlags.filter((lf) => lf.id !== flag.id);
                    liveFlags.push(flag);
                }
            }

            return {
                ...oldData,
                liveFlags,
            };
        });
    }
};
