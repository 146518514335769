import useTeamCrest from 'app/hooks/useTeamCrest';
import iconShield from 'assets/svg/icon-shield.svg';
import { IconDecorated } from 'components/icons';
import Tooltip from 'components/primitives/tooltip';
import SportradarImage from 'components/sr-logo';
import useDateSnapshot from 'hooks/useDateSnapshot';

import { FlagContentInternal, VisibleContent } from '../../styles';

import { TeamCrest, TeamCrestPlaceholder } from './styles';

interface HeaderInternalProps {
    awayTeamSrId?: string;
    homeTeamSrId?: string;
    text: string;
    ignoredCount: number;
    createdCount: number;
    createdAt: string;
    isToggled: boolean;
    handleToggle: () => void;
}

const HeaderInternal = ({
    text,
    ignoredCount,
    createdCount,
    createdAt,
    isToggled,
    handleToggle,
    awayTeamSrId,
    homeTeamSrId,
}: HeaderInternalProps) => {
    const createDate = useDateSnapshot(createdAt);
    const awayTeamCrest = useTeamCrest(awayTeamSrId);
    const homeTeamCrest = useTeamCrest(homeTeamSrId);

    return (
        <Tooltip disableInteractive placement="left" title={`Created at ${createDate}`}>
            <VisibleContent
                visible={isToggled}
                onClick={handleToggle}
                data-testid="flag-boxscore-header-internal"
            >
                <TeamCrest>
                    <SportradarImage
                        className={'crest'}
                        src={awayTeamCrest}
                        heightRem={1.5}
                        placeholder={<TeamCrestPlaceholder src={iconShield} />}
                    />
                </TeamCrest>
                @
                <TeamCrest>
                    <SportradarImage
                        className={'crest'}
                        src={homeTeamCrest}
                        heightRem={1.5}
                        placeholder={<TeamCrestPlaceholder src={iconShield} />}
                    />
                </TeamCrest>
                <FlagContentInternal>
                    <div className="text">{text}</div>
                    <div className="stats">
                        <div className="flagged">
                            <IconDecorated sizeRem={0.75} name="Flag" paletteColor="red600" />
                            <span>{createdCount}</span>
                        </div>
                        <div className="ignored">
                            <IconDecorated sizeRem={0.75} name="Texture" paletteColor="black300" />
                            <span>{ignoredCount}</span>
                        </div>
                    </div>
                </FlagContentInternal>
            </VisibleContent>
        </Tooltip>
    );
};

export default HeaderInternal;
