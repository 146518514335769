import * as React from 'react';
import { Route } from 'type-route';

import { TeamSorting, TeamStatus } from 'api/types/bff';
import { Division } from 'api/types/leagues';
import { routes } from 'app/router';
import useLeagueDetails from 'app/useLeagueDetails';
import ControlBar from 'components/control-bar';
import ControlPanel from 'components/control-panel';
import Dropdown from 'components/dropdown';
import { IconDecorated } from 'components/icons';
import Tooltip from 'components/primitives/tooltip';
import SourceSelector from 'components/source-selector';
import View from 'components/view';
import ViewHeader from 'components/view-header';
import { useLeagueHierarchyOptions } from 'hooks/options/useLeagueHierarchyOptions';
import { useChangeParams } from 'hooks/useChangeParams';
import useDateSnapshot from 'hooks/useDateSnapshot';
import useDivisions from 'hooks/useDivisions';
import { ExternalSourceId, SourceId } from 'types';

import Loading from '../../../components/loading';

import TeamListItem from './components/team-list-item';
import { useTeamSortingOptions } from './hooks/useTeamSortingOptions';
import { useTeamStatusOptions } from './hooks/useTeamStatusOptions';
import useTeams from './hooks/useTeams';
import { useTeamsSourceSelector } from './hooks/useTeamsSourceSelector';
import TeamsTabMenu from './teams-tab-menu';

type Props = {
    route: Route<typeof routes.teams>;
};

const Teams = ({ route }: Props) => {
    const leagueDetails = useLeagueDetails(route.params.league);
    const source = route.params.source ?? leagueDetails.sources[0];

    const divisionAlias = !route.params.division
        ? leagueDetails.teamPageDefaultDivision
        : route.params.division === 'all'
        ? undefined
        : route.params.division;

    const valuesBySource = useTeamsSourceSelector(leagueDetails.sources);

    const statusOptions = useTeamStatusOptions(route.params.status);

    const divisionsQuery = useDivisions(
        { league: route.params.league },
        { enabled: leagueDetails.filters.leagueHierarchy },
    );
    const divisionOptions = useLeagueHierarchyOptions(divisionsQuery.data, divisionAlias);

    const sortingOptions = useTeamSortingOptions(route.params.sorting);

    const teamsQuery = useTeams(
        route.params.league,
        route.params.seasonYear,
        route.params.seasonType,
        source,
        route.params.sorting,
        route.params.status,
        divisionAlias,
    );

    const updateDate = useDateSnapshot(teamsQuery.dataUpdatedAt);

    const handleParamsChange = useChangeParams(route);

    const handleSourceChange = React.useCallback(
        (sourceId: SourceId) => handleParamsChange({ source: sourceId as ExternalSourceId }),
        [handleParamsChange],
    );
    const handleSortingChange = React.useCallback(
        (sorting: TeamSorting) => handleParamsChange({ sorting }),
        [handleParamsChange],
    );
    const handleDivisionChange = React.useCallback(
        (division?: Division) => handleParamsChange({ division: division?.alias ?? 'all' }),
        [handleParamsChange],
    );
    const handleStatusChange = React.useCallback(
        (status?: TeamStatus) => handleParamsChange({ status }),
        [handleParamsChange],
    );

    return (
        <View route={route}>
            <ViewHeader
                iconElement={
                    <IconDecorated sizeRem={1.5} paletteColor="blue300" name="GroupWorkRounded" />
                }
                titleText="Teams"
            >
                <ControlBar spacing="small">
                    <Tooltip title="Last time the teams were pulled from the system">
                        <ControlBar.Text>Updated {updateDate}</ControlBar.Text>
                    </Tooltip>
                    <Tooltip
                        disabled={teamsQuery.isFetching}
                        disableInteractive
                        title="Click to refresh the teams"
                    >
                        <ControlBar.Button
                            disabled={teamsQuery.isFetching}
                            onClick={() => teamsQuery.refetch()}
                        >
                            {teamsQuery.isFetching ? 'Refreshing...' : 'Refresh'}
                        </ControlBar.Button>
                    </Tooltip>
                </ControlBar>
            </ViewHeader>
            <TeamsTabMenu route={route} />
            <SourceSelector
                valuesBySource={valuesBySource}
                onSourceClick={handleSourceChange}
                activeSource={source}
            />
            <ControlPanel>
                <ControlPanel.Group>
                    <Dropdown
                        label="Sort"
                        options={sortingOptions}
                        onChange={handleSortingChange}
                    />
                    {leagueDetails.filters.leagueHierarchy && (
                        <Dropdown
                            label="Division"
                            options={divisionOptions}
                            onChange={handleDivisionChange}
                        />
                    )}
                    <Dropdown
                        label="Status"
                        options={statusOptions}
                        onChange={handleStatusChange}
                    />
                </ControlPanel.Group>
            </ControlPanel>
            <div data-testid="flags">
                {teamsQuery.isLoading && <Loading mt={2} />}
                {teamsQuery.data?.teams.map((team) => {
                    const created =
                        teamsQuery.data?.stats?.[team.srId]?.[source]?.['CREATED']?.value ?? 0;
                    const ignored =
                        teamsQuery.data?.stats?.[team.srId]?.[source]?.['IGNORED']?.value ?? 0;

                    return (
                        <TeamListItem
                            key={`team-${team.srId}`}
                            team={team}
                            route={route}
                            createdNumber={
                                ['flagged', 'all'].includes(route.params.status)
                                    ? created ?? 0
                                    : null
                            }
                            ignoredNumber={
                                ['ignored', 'all'].includes(route.params.status)
                                    ? ignored ?? 0
                                    : null
                            }
                        />
                    );
                })}
            </div>
        </View>
    );
};

export default Teams;
