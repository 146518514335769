import * as React from 'react';

import { MappingEntity } from 'api/types/mapper';
import Tooltip from 'components/primitives/tooltip';
import { useMappingEntityAttributes } from 'hooks/mapping/useMappingEntityAttributes';

import { MappingItemTooltipContainer } from './styles';

type Props = {
    children: React.ComponentProps<typeof Tooltip>['children'];
    entity: MappingEntity;
};

export const MappingItemTooltip = (props: Props) => {
    const entityAttributes = useMappingEntityAttributes(props.entity, null);

    const tooltipTitle = React.useMemo(
        () => (
            <MappingItemTooltipContainer>
                {Object.entries(entityAttributes)
                    .filter((entry): entry is [string, string] => !!entry[1])
                    .map(([key, value]) => (
                        <React.Fragment key={key}>
                            <p className="mapping-tooltip-attribute">
                                <span>{key} </span>
                                <span className="mapping-tooltip-value">{value}</span>
                            </p>
                        </React.Fragment>
                    ))}
            </MappingItemTooltipContainer>
        ),
        [entityAttributes],
    );

    return (
        <Tooltip disableInteractive title={tooltipTitle}>
            {props.children}
        </Tooltip>
    );
};
