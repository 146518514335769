import styled from '@emotion/styled';

export const MappingItemContainer = styled.div<{ hasMappings: boolean }>`
    cursor: ${(props) => (props.hasMappings ? 'pointer' : 'inherit')};

    .mapping-item-header {
        display: flex;
        align-items: center;
    }

    .mapping-item-icon {
        margin-right: 0.75rem;
    }

    .mapping-item-content {
        display: flex;
        align-items: center;
        flex: 1;
        border-bottom: 1px solid var(--color-borderline-secondary);
        min-height: 2.5rem;
        padding: 0 0.25rem;
    }

    .mapping-item-button-group {
        display: flex;
        column-gap: 0.5rem;
    }

    .mapping-item-mappings-list {
        margin-left: 1.75rem;
        margin-bottom: 1rem;
    }
`;
