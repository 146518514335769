import styled from '@emotion/styled';

export const MappingItemTooltipContainer = styled.div`
    .mapping-tooltip-attribute {
        color: var(--color-text);
        font-size: 0.75rem;
        line-height: 0.5rem;
        font-weight: 700;
    }

    .mapping-tooltip-value {
        opacity: 0.6;
    }
`;
