import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const appear = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const TaskViewContainer = styled.div`
    display: flex;
    flex: 1;
    padding-top: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-direction: column;
    gap: 2rem;
`;

export const GroupedTaskContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    max-height: 40rem;
    overflow-y: auto;
`;

export const TaskAccordionSummary = styled.div`
    display: grid;
    flex-direction: column;
    grid-template-columns: 22rem 10rem 16rem 14rem 7rem 2rem;
    width: min-content;
    align-items: center;

    animation: ${appear} 1s ease forwards;

    & > span {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        white-space: nowrap;
    }
`;

export const TaskSummaryContainerRow = styled.div`
    display: grid;
    width: 100%;
    gap: 1rem;
    grid-template-columns: repeat(5, 1fr) min-content;
    font-size: 0.875rem;
    padding-left: 1rem;
    font-weight: bold;
    padding-bottom: 0.5rem;
`;

export const TaskAccordionDetails = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
`;

export const ProgressStatusLabelContainer = styled.div`
    width: min-content;
    height: min-content;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
`;
