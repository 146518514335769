import * as typeRoute from 'type-route';

import { FlagsSortingAttribute, TeamSorting, TeamStatus } from 'api/types/bff';
import { DiscrepancyStatus } from 'api/types/flags';
import { LiveFlags } from 'api/types/live-flags';
import { MappingType } from 'api/types/mapper';
import { Order } from 'types/sorting';

import * as serializers from './serializers';

export const home = typeRoute.defineRoute('/');

export const admin = typeRoute.defineRoute('/admin');

export const adminUserListing = admin.extend('/users');
export const adminTasksListing = admin.extend('/tasks');

export const adminUserView = adminUserListing.extend(
    {
        id: typeRoute.param.path.string,
    },
    ({ id }) => `/${id}`,
);

export const league = typeRoute.defineRoute(
    {
        league: typeRoute.param.path.ofType(serializers.league),
        seasonYear: typeRoute.param.path.ofType(serializers.seasonYear),
        seasonType: typeRoute.param.path.ofType(serializers.seasonType),
    },
    ({ league, seasonYear, seasonType }) => `/${league}/${seasonYear}/${seasonType}`,
);

export const dashboard = league.extend(
    {
        status: typeRoute.param.query.optional
            .ofType(serializers.flagsDiscrepancyStatus)
            .default(DiscrepancyStatus.CREATED),
        sortBy: typeRoute.param.query.optional
            .ofType(serializers.flagsSortBy)
            .default(FlagsSortingAttribute.DiscrepancyDate),
        sortOrder: typeRoute.param.query.optional.ofType(serializers.sortOrder).default(Order.Desc),
    },
    () => '/dashboard',
);

export const standings = league.extend(
    {
        status: typeRoute.param.query.optional
            .ofType(serializers.flagsDiscrepancyStatus)
            .default(DiscrepancyStatus.CREATED),
        sortBy: typeRoute.param.query.optional
            .ofType(serializers.flagsSortBy)
            .default(FlagsSortingAttribute.DiscrepancyDate),
        sortOrder: typeRoute.param.query.optional.ofType(serializers.sortOrder).default(Order.Desc),
    },
    () => '/standings',
);

export const polls = league.extend(
    {
        status: typeRoute.param.query.optional
            .ofType(serializers.flagsDiscrepancyStatus)
            .default(DiscrepancyStatus.CREATED),
        division: typeRoute.param.query.optional.string,
        conference: typeRoute.param.query.optional.string,
        week: typeRoute.param.query.optional.string,
        sortBy: typeRoute.param.query.optional
            .ofType(serializers.flagsSortBy)
            .default(FlagsSortingAttribute.DiscrepancyDate),
        sortOrder: typeRoute.param.query.optional.ofType(serializers.sortOrder).default(Order.Desc),
    },
    () => '/pool-rankings',
);

export const schedule = league.extend(
    {
        status: typeRoute.param.query.optional
            .ofType(serializers.flagsDiscrepancyStatus)
            .default(DiscrepancyStatus.CREATED),
        division: typeRoute.param.query.optional.string,
        conference: typeRoute.param.query.optional.string,
        week: typeRoute.param.query.optional.string,
        dateFrom: typeRoute.param.query.optional.ofType(serializers.date),
        dateTo: typeRoute.param.query.optional.ofType(serializers.date),
        sortBy: typeRoute.param.query.optional
            .ofType(serializers.flagsSortBy)
            .default(FlagsSortingAttribute.DiscrepancyDate),
        sortOrder: typeRoute.param.query.optional.ofType(serializers.sortOrder).default(Order.Desc),
    },
    () => '/schedule',
);

export const gameBoxscores = league.extend(
    {
        status: typeRoute.param.query.optional
            .ofType(serializers.flagsDiscrepancyStatus)
            .default(DiscrepancyStatus.CREATED),
        week: typeRoute.param.query.optional.string,
        dateFrom: typeRoute.param.query.optional.ofType(serializers.date),
        dateTo: typeRoute.param.query.optional.ofType(serializers.date),
        sortBy: typeRoute.param.query.optional
            .ofType(serializers.flagsSortBy)
            .default(FlagsSortingAttribute.DiscrepancyDate),
        sortOrder: typeRoute.param.query.optional.ofType(serializers.sortOrder).default(Order.Desc),
    },
    () => '/game-boxscores',
);

export const gameBoxscoresGames = league.extend(
    {
        status: typeRoute.param.query.optional.ofType(serializers.gameStatus),
        coverage: typeRoute.param.query.optional.ofType(serializers.gameCoverage),
        division: typeRoute.param.query.optional.string,
        week: typeRoute.param.query.optional.string,
        dateFrom: typeRoute.param.query.optional.ofType(serializers.date),
        dateTo: typeRoute.param.query.optional.ofType(serializers.date),
        sortBy: typeRoute.param.query.optional
            .ofType(serializers.flagsSortBy)
            .default(FlagsSortingAttribute.EventDate),
        sortOrder: typeRoute.param.query.optional.ofType(serializers.sortOrder).default(Order.Asc),
    },
    () => '/game-boxscores/games',
);

export const gameBoxscoresGame = league.extend(
    {
        id: typeRoute.param.path.string,
        liveSource: typeRoute.param.query.optional.ofType(serializers.externalSource),
        liveStatus: typeRoute.param.query.optional
            .ofType(serializers.liveFlagStatus)
            .default(LiveFlags.FlagStatus.CREATED),
        liveCategory: typeRoute.param.query.optional.ofType(serializers.liveFlagCategoryName),
        liveType: typeRoute.param.query.optional.string,
        liveLimit: typeRoute.param.query.optional.number,
        status: typeRoute.param.query.optional
            .ofType(serializers.flagsDiscrepancyStatus)
            .default(DiscrepancyStatus.CREATED),
        source: typeRoute.param.query.optional.ofType(serializers.externalSource),
    },
    ({ id }) => `/game-boxscores/games/${id}`,
);

export const seasonal = league.extend(
    {
        status: typeRoute.param.query.optional
            .ofType(serializers.flagsDiscrepancyStatus)
            .default(DiscrepancyStatus.CREATED),
        division: typeRoute.param.query.optional.string,
        conference: typeRoute.param.query.optional.string,
        sortBy: typeRoute.param.query.optional
            .ofType(serializers.flagsSortBy)
            .default(FlagsSortingAttribute.DiscrepancyDate),
        sortOrder: typeRoute.param.query.optional.ofType(serializers.sortOrder).default(Order.Desc),
    },
    () => '/seasonal',
);

export const teams = league.extend(
    {
        source: typeRoute.param.query.optional.ofType(serializers.externalSource),
        status: typeRoute.param.query.optional
            .ofType(serializers.teamStatus)
            .default(TeamStatus.All),
        division: typeRoute.param.query.optional.string,
        sorting: typeRoute.param.query.optional
            .ofType(serializers.teamSorting)
            .default(TeamSorting.ALPHABETICAL_ASC),
    },
    () => '/teams',
);

export const teamsMappings = league.extend('/teams/mappings');

export const teamsTeam = league.extend(
    {
        id: typeRoute.param.path.string,
        status: typeRoute.param.query.optional
            .ofType(serializers.flagsDiscrepancyStatus)
            .default(DiscrepancyStatus.CREATED),
        sortBy: typeRoute.param.query.optional
            .ofType(serializers.flagsSortBy)
            .default(FlagsSortingAttribute.DiscrepancyDate),
        sortOrder: typeRoute.param.query.optional.ofType(serializers.sortOrder).default(Order.Desc),
    },
    ({ id }) => `/teams/${id}`,
);

export const teamsTeamRoster = league.extend(
    {
        id: typeRoute.param.path.string,
        status: typeRoute.param.query.optional
            .ofType(serializers.flagsDiscrepancyStatus)
            .default(DiscrepancyStatus.CREATED),
    },
    ({ id }) => `/teams/${id}/roster`,
);

export const teamsTeamSeasonal = league.extend(
    {
        id: typeRoute.param.path.string,
        status: typeRoute.param.query.optional
            .ofType(serializers.flagsDiscrepancyStatus)
            .default(DiscrepancyStatus.CREATED),
        sortBy: typeRoute.param.query.optional
            .ofType(serializers.flagsSortBy)
            .default(FlagsSortingAttribute.DiscrepancyDate),
        sortOrder: typeRoute.param.query.optional.ofType(serializers.sortOrder).default(Order.Desc),
    },
    ({ id }) => `/teams/${id}/seasonal`,
);

export const teamsTeamSchedule = league.extend(
    {
        id: typeRoute.param.path.string,
        status: typeRoute.param.query.optional
            .ofType(serializers.flagsDiscrepancyStatus)
            .default(DiscrepancyStatus.CREATED),
        sortBy: typeRoute.param.query.optional
            .ofType(serializers.flagsSortBy)
            .default(FlagsSortingAttribute.DiscrepancyDate),
        sortOrder: typeRoute.param.query.optional.ofType(serializers.sortOrder).default(Order.Desc),
    },
    ({ id }) => `/teams/${id}/schedule`,
);

export const teamsTeamPolls = league.extend(
    {
        id: typeRoute.param.path.string,
        status: typeRoute.param.query.optional
            .ofType(serializers.flagsDiscrepancyStatus)
            .default(DiscrepancyStatus.CREATED),
        sortBy: typeRoute.param.query.optional
            .ofType(serializers.flagsSortBy)
            .default(FlagsSortingAttribute.DiscrepancyDate),
        sortOrder: typeRoute.param.query.optional.ofType(serializers.sortOrder).default(Order.Desc),
    },
    ({ id }) => `/teams/${id}/polls`,
);

export const teamsTeamStandings = league.extend(
    {
        id: typeRoute.param.path.string,
        status: typeRoute.param.query.optional
            .ofType(serializers.flagsDiscrepancyStatus)
            .default(DiscrepancyStatus.CREATED),
    },
    ({ id }) => `/teams/${id}/standings`,
);

export const teamsTeamMapping = league.extend(
    {
        id: typeRoute.param.path.string,
        source: typeRoute.param.query.optional.ofType(serializers.externalSource),
        type: typeRoute.param.query.optional
            .ofType(serializers.teamMappingType)
            .default(MappingType.PLAYER),
    },
    ({ id }) => `/teams/${id}/mapping`,
);

export const rosters = league.extend(
    {
        status: typeRoute.param.query.optional
            .ofType(serializers.flagsDiscrepancyStatus)
            .default(DiscrepancyStatus.CREATED),
        division: typeRoute.param.query.optional.string,
        conference: typeRoute.param.query.optional.string,
        sortBy: typeRoute.param.query.optional
            .ofType(serializers.flagsSortBy)
            .default(FlagsSortingAttribute.DisplayName),
        sortOrder: typeRoute.param.query.optional.ofType(serializers.sortOrder).default(Order.Asc),
    },
    () => '/rosters',
);
