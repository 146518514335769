import styled from '@emotion/styled';
import { ButtonHTMLAttributes } from 'react';

export interface StyledButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    disabled?: boolean; //overrides ButtonHTMLAttributes<HTMLButtonElement>
    inserted?: boolean;
    noBorder?: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
    display: block;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    color: var(--color-button);
    background-color: var(
        ${(props) => (props.inserted ? '--color-highlight' : '--color-component-background')}
    );
    opacity: ${(props) => (props.disabled ? 0.25 : 1)};
    border-radius: 0.3125rem;
    border-width: 0.0625rem;
    border-style: solid;
    border-color: ${(props) => (props.noBorder ? 'transparent' : 'var(--color-button)')};
    padding: 0.5rem;
    transition: opacity 100ms ease-in-out;
`;
