import * as React from 'react';
import { Route } from 'type-route';

import { MappingStatus, MappingType } from 'api/types/mapper';
import { routes } from 'app/router';
import TabMenu from 'components/tab-menu';
import TabButton from 'components/tab-menu/tab-button';
import { useTeamMappingsStatsByType } from 'hooks/mapping/useTeamMappingStatsByType';
import { useTeamMappingsStatsQuery } from 'hooks/mapping/useTeamMappingsStatsQuery';
import { SourceId } from 'types';

const MAPPINGS_TYPE_LABEL_MAP: Record<MappingType, string> = {
    [MappingType.PLAYER]: 'Players',
    [MappingType.SCHEDULE]: 'Schedules',
};

type Props = {
    route: Route<typeof routes.teamsTeamMapping>;
    source: SourceId;
};

export const NavigationMapping = (props: Props) => {
    const mappingsStatsQuery = useTeamMappingsStatsQuery({
        league: props.route.params.league,
        teamId: props.route.params.id,
    });
    const mappingsStatsByType = useTeamMappingsStatsByType(mappingsStatsQuery.data);

    const handleTabClick = React.useCallback(
        (type: MappingType) =>
            routes
                .teamsTeamMapping({
                    league: props.route.params.league,
                    seasonType: props.route.params.seasonType,
                    seasonYear: props.route.params.seasonYear,
                    id: props.route.params.id,
                    type,
                })
                .push(),
        [props.route.params],
    );

    return (
        <TabMenu>
            {Object.values(MappingType).map((type) => (
                <TabButton
                    active={props.route.params.type === type}
                    dotted={mappingsStatsByType[type][MappingStatus.UNMAPPED] > 0}
                    key={type}
                    onClick={() => handleTabClick(type)}
                >
                    {MAPPINGS_TYPE_LABEL_MAP[type]}
                </TabButton>
            ))}
        </TabMenu>
    );
};
