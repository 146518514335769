import { MappingStatus, MappingType } from 'api/types/mapper';
import GameMappingIndicator from 'components/mapping-indicator';
import TabMenu from 'components/tab-menu';
import TabButton from 'components/tab-menu/tab-button';
import { useTeamMappingsStatsByType } from 'hooks/mapping/useTeamMappingStatsByType';
import { useTeamMappingsStatsQuery } from 'hooks/mapping/useTeamMappingsStatsQuery';
import { LeagueType } from 'types';

import { ViewType } from '../../types';

type Props = {
    league: LeagueType;
    awayTeamId?: string;
    awayTeamGlobalId?: string | null;
    awayTeamName?: string;
    awayTeamAlias?: string;
    homeTeamId?: string;
    homeTeamGlobalId?: string | null;
    homeTeamName?: string;
    homeTeamAlias?: string;
    gameHasFinished?: boolean;
    externalSchedules: {
        id: string;
        source: string;
    }[];
    onTeamViewOpen: (teamId: string) => void;
    gameView: ViewType;
    onChange: (gameView: ViewType) => void;
};

const GameViewTabs = ({
    league,
    awayTeamId,
    awayTeamGlobalId,
    awayTeamName = 'Away',
    awayTeamAlias = 'Away',
    homeTeamId,
    homeTeamGlobalId,
    homeTeamName = 'Home',
    homeTeamAlias = 'Home',
    gameHasFinished,
    externalSchedules,
    onTeamViewOpen,
    gameView,
    onChange,
}: Props) => {
    const awayMappingsStatsQuery = useTeamMappingsStatsQuery(
        { league, teamId: awayTeamId as string },
        { enabled: !!awayTeamId },
    );
    const homeMappingsStatsQuery = useTeamMappingsStatsQuery(
        { league, teamId: homeTeamId as string },
        { enabled: !!homeTeamId },
    );

    const awayMappingsStatsByType = useTeamMappingsStatsByType(awayMappingsStatsQuery.data);
    const homeMappingsStatsByType = useTeamMappingsStatsByType(homeMappingsStatsQuery.data);

    return (
        <TabMenu>
            <TabButton
                active={gameView === ViewType.LIVE_GAME}
                onClick={() => onChange(ViewType.LIVE_GAME)}
            >
                Live Game
            </TabButton>
            {gameHasFinished && (
                <TabButton
                    active={gameView === ViewType.POST_GAME}
                    onClick={() => onChange(ViewType.POST_GAME)}
                >
                    Post Game
                </TabButton>
            )}
            <div style={{ flexGrow: 1 }} />
            <GameMappingIndicator
                teams={[
                    {
                        srId: awayTeamId!,
                        globalId: awayTeamGlobalId!,
                        name: awayTeamName,
                        abbreviation: awayTeamAlias,
                    },
                    {
                        srId: homeTeamId!,
                        globalId: homeTeamGlobalId!,
                        name: homeTeamName,
                        abbreviation: homeTeamAlias,
                    },
                ]}
                externalSchedules={externalSchedules}
                onTeamViewOpen={onTeamViewOpen}
            />
            {awayTeamId && (
                <TabButton
                    active={gameView === ViewType.AWAY_MAPPINGS}
                    dotted={awayMappingsStatsByType[MappingType.PLAYER][MappingStatus.UNMAPPED] > 0}
                    onClick={() => onChange(ViewType.AWAY_MAPPINGS)}
                >
                    {`${awayTeamAlias} - Mapping`}
                </TabButton>
            )}
            {homeTeamId && (
                <TabButton
                    active={gameView === ViewType.HOME_MAPPINGS}
                    dotted={homeMappingsStatsByType[MappingType.PLAYER][MappingStatus.UNMAPPED] > 0}
                    onClick={() => onChange(ViewType.HOME_MAPPINGS)}
                >
                    {`${homeTeamAlias} - Mapping`}
                </TabButton>
            )}
        </TabMenu>
    );
};

export default GameViewTabs;
