import * as React from 'react';

import { Option } from 'types/controls';
import { Order } from 'types/sorting';

const DEFAULT_OPTIONS: Option<Order>[] = [
    { label: 'ALPHABETICAL (A-Z)', value: Order.Asc },
    { label: 'ALPHABETICAL (Z-A)', value: Order.Desc },
];

export function useMappingSortingOptions(current: Order): Option<Order>[] {
    return React.useMemo(
        () =>
            DEFAULT_OPTIONS.map((option) => ({
                label: option.label,
                value: option.value,
                selected: option.value === current,
            })),
        [current],
    );
}
