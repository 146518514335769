import { BallPossessedBy } from 'components/game-card/types';
import { LeagueType } from 'types';
import { Coverage } from 'types/coverage';

import * as FlagService from './flags';
import * as LeaguesService from './leagues';
import { LiveFlags } from './live-flags';

export enum FlagsSortingAttribute {
    DiscrepancyDate = 'discrepancy_date',
    DiscrepancyCount = 'discrepancy_count',
    DisplayName = 'display_name',
    EventDate = 'event_date',
}

export function isFlagSortingAttribute(attribute: unknown): attribute is FlagsSortingAttribute {
    return Object.values(FlagsSortingAttribute).includes(attribute as FlagsSortingAttribute);
}

export enum TeamStatus {
    All = 'all',
    Flagged = 'flagged',
    Ignored = 'ignored',
}

export function isTeamStatus(status: unknown): status is TeamStatus {
    return Object.values(TeamStatus).includes(status as TeamStatus);
}

export enum TeamSorting {
    ALPHABETICAL_ASC = 'a_z',
    ALPHABETICAL_DESC = 'z_a',
    MOST_RECENT = 'most_recent',
    OLDEST = 'oldest',
}

export function isTeamSorting(sorting: unknown): sorting is TeamSorting {
    return Object.values(TeamSorting).includes(sorting as TeamSorting);
}

export type TeamsResponse = {
    teams: LeaguesService.Team[];
    stats: ContextStats;
};

export type ContextStats = Record<string, StatsSource | undefined>;
export type StatsSource = Record<string, StatsType>;
export type StatsType = Record<string, Stat>;
export type Stat = {
    value: number | null;
    lastFlagCreatedAt: string | null;
};

export type DiscrepancyType = {
    contextTeam: Context.Team | null;
    contextPlayer: Context.Player | null;
    contextGame: Context.Game | null;
} & Omit<FlagService.DiscrepancyType, 'contextGameSrId' | 'contextTeamSrId' | 'contextPlayerSrId'>;

export type FlagType = {
    context: Context.Game | Context.Team | Context.Player;
} & Omit<FlagService.FlagType, 'contextSrId'>;

export type ExternalLink = {
    title: string;
    url: string;
};

export namespace Context {
    export const isGame = (context: Flag): context is Game =>
        context.hasOwnProperty('srId') &&
        context.hasOwnProperty('scheduledDate') &&
        context.hasOwnProperty('awayTeam') &&
        context.hasOwnProperty('homeTeam');

    export const isTeam = (context: Flag): context is Team =>
        context.hasOwnProperty('srId') &&
        context.hasOwnProperty('name') &&
        context.hasOwnProperty('conference');

    export const isPlayer = (context: Flag): context is Player =>
        context.hasOwnProperty('srId') && context.hasOwnProperty('name');

    export type Flag = Game | Team | Player;

    export type Game = {
        srId: string;
        srGlobalId?: string;
        scheduledDate?: string;
        awayTeam?: Team;
        homeTeam?: Team;
    };

    export type Team = {
        srId: string;
        srGlobalId?: string;
        name?: string;
        alias?: string;
        market?: string;
        conference?: string;
        division?: string;
    };

    export type Player = {
        srId: string;
        srGlobalId?: string;
        name?: string;
    };
}

export namespace Game {
    export type GameTeam = {
        srId?: string;
        srGlobalId?: string | null;
        alias?: string;
        name?: string;
        market?: string;
    };

    export type GameTeamScore = {
        value?: number;
        isMarked?: boolean;
    };

    export type GameScoringPeriod = {
        type?: string;
        number?: number;
        sequence?: number;
        awayPoints?: number;
        homePoints?: number;
    };

    export type Game = {
        // League Service - Core
        srId: string;
        srGlobalId?: string;
        status?: LeaguesService.GameStatus;
        scheduledDate?: string;
        coverage?: Coverage;
        attendance?: number;
        sequenceInfo?: string;
        awayTeam?: GameTeam;
        awayTeamScores?: GameTeamScore[];
        homeTeamTotalScore?: GameTeamScore;
        homeTeam?: GameTeam;
        homeTeamScores?: GameTeamScore[];
        awayTeamTotalScore?: GameTeamScore;
        // League Service - NCAAFB
        duration?: string;
        quarterInfo?: string;
        weather?: string;
        // League Service - NCAAMB / NCAAWB
        leadChanges?: number;
        timesTied?: number;
        awayTeamPointsInPaint?: number;
        awayTeamPointsOffTurnovers?: number;
        awayTeamFastBreakPoints?: number;
        awayTeamSecondChancePoints?: number;
        homeTeamPointsInPaint?: number;
        homeTeamPointsOffTurnovers?: number;
        homeTeamFastBreakPoints?: number;
        homeTeamSecondChancePoints?: number;
    };

    export type LiveGameFlag = LiveFlags.LiveFlag & {
        playerName: string | null;
        teamName: string | null;
    };

    export type LiveGame = Game & {
        liveFlags: LiveGameFlag[];
        flagStats: LiveFlags.StatusCount;
        externalSchedules: {
            id: string;
            source: string;
        }[];
    };
}

export namespace Games {
    export type TeamScore = {
        value: number;
        isMarked?: boolean;
    };

    export type Game = {
        id: string;
        league: LeagueType;
        status: LeaguesService.GameStatus;
        subtitle: string;
        sequenceInfo?: string;
        coverage?: string;
        awayTeamSrId: string | null;
        awayTeamGlobalId: string | null;
        awayTeamName: string;
        awayTeamAlias: string;
        awayTeamDivision: string;
        homeTeamSrId: string | null;
        homeTeamGlobalId: string | null;
        homeTeamName: string;
        homeTeamAlias: string;
        homeTeamDivision: string;
        ballPossessedBy: BallPossessedBy;
        scheduledDate: string;
        flagsCreated?: number;
        flagsIgnored?: number;
        awayTeamScores?: TeamScore[];
        homeTeamScores?: TeamScore[];
        homeTeamTotalScore?: TeamScore;
        awayTeamTotalScore?: TeamScore;
        externalSchedules?: {
            id: string;
            source: string;
        }[];
    };
}

export namespace Admin {
    export enum ScheduleTypes {
        Game = 'game',
        TeamGames = 'team-games',
        TeamSeasonal = 'team-seasonal',
        TeamSchedule = 'team-schedule',
        TeamStandings = 'team-standings',
        TeamPolls = 'team-polls',
        TeamRoster = 'team-roster',
    }

    export function isScheduleType(type: unknown): type is ScheduleTypes {
        return Object.values(ScheduleTypes).includes(type as ScheduleTypes);
    }
}
