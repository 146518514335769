import * as React from 'react';

import { MappingEntity } from 'api/types/mapper';
import { Option } from 'types/controls';

export function useMappingEntityOptions(
    entities: MappingEntity[],
    current?: MappingEntity,
): Option<MappingEntity>[] {
    return React.useMemo(
        () =>
            entities
                .map((entity) => ({
                    label: entity.name ?? entity.id,
                    value: entity,
                    selected: entity.id === current?.id,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        [current, entities],
    );
}
