import { mqtt5 } from 'aws-iot-device-sdk-v2';
import { once } from 'events';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';

import Env from '../lib/env';

import { createClient } from './client';
import { AWSCognitoCredentialsProvider } from './cognito-credentials-provider';
import { useProcessWebsocketMessage } from './use-process-websocket-message';
import WsContext from './ws-context';
type WsProviderProps = React.PropsWithChildren<{}>;

const WsProvider = ({ children }: WsProviderProps) => {
    const didInitialise = useRef(false);
    const [connected, setConnected] = useState(false);
    const { process } = useProcessWebsocketMessage();
    const wsClient: mqtt5.Mqtt5Client | null = React.useMemo(() => {
        /** Set up the credentialsProvider */
        const provider = new AWSCognitoCredentialsProvider({
            IdentityPoolId: Env.get('REACT_APP_COGNITO_IDENTITY_POOL_ID'),
            Region: Env.get('REACT_APP_AWS_REGION'),
        });

        (async () => {
            /** Make sure the credential provider fetched before setup the connection */
            await provider.refreshCredentials();
        })();

        return createClient(provider);
    }, []);

    useEffect(() => {
        if (connected) {
            return;
        }

        (async () => {
            const attemptingConnect = once(wsClient, 'attemptingConnect');
            const connectionSuccess = once(wsClient, 'connectionSuccess');

            try {
                wsClient.start();

                await attemptingConnect;
                await connectionSuccess;
                setConnected(true);
                didInitialise.current = true;
            } catch (error) {
                console.error('Error connecting to MQTT: ', error);
                setConnected(false);
            }

            wsClient.on('messageReceived', (eventData: mqtt5.MessageReceivedEvent): void => {
                process(eventData);
            });

            wsClient.on('connectionSuccess', () => {
                setConnected(true);
                didInitialise.current = true;
            });

            wsClient.on('disconnection', () => {
                console.debug('ws:disconnected');
                setConnected(false);
            });

            wsClient.on('connectionFailure', () => {
                console.debug('ws:connectionFailure');
                setConnected(false);
            });

            wsClient.on('stopped', () => {
                console.debug('ws:stopped');
                setConnected(false);
            });
        })();
    }, [process, connected, wsClient]);

    const subscribe = React.useCallback(
        async (topic: string, qos: mqtt5.QoS) => {
            try {
                if (wsClient) {
                    await wsClient.subscribe({
                        subscriptions: [{ qos: qos, topicFilter: topic }],
                    });
                }
            } catch (e) {
                console.warn(
                    'Ws-provider: could not subscribe to topic: %s',
                    topic,
                    { connected, didInitialise: didInitialise.current },
                    e,
                    wsClient,
                );
            }
        },
        [wsClient, connected],
    );

    const unsubscribe = React.useCallback(
        async (topic: string) => {
            try {
                if (wsClient) {
                    await wsClient.unsubscribe({
                        topicFilters: [topic],
                    });
                }
            } catch (e) {
                console.warn(
                    'Ws-provider: could not unsubscribe to topic: %s, e: %s',
                    topic,
                    JSON.stringify(e),
                );
            }
        },
        [wsClient],
    );

    return (
        <WsContext.Provider
            value={{
                client: wsClient,
                subscribe,
                unsubscribe,
                didInitialise: didInitialise.current,
                connected: connected,
            }}
        >
            {children}
        </WsContext.Provider>
    );
};

export default WsProvider;
