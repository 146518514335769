import * as React from 'react';

import { MappingEntity } from 'api/types/mapper';
import Dropdown from 'components/dropdown';
import Button from 'components/primitives/button';
import { useMappingEntityAttributes } from 'hooks/mapping/useMappingEntityAttributes';
import { useMappingEntityOptions } from 'hooks/mapping/useMappingEntityOptions';
import { SourceId } from 'types';

import { AttributesCard, Card, DropdownStyles } from '../styles';

type Props = {
    dropdownEntities: MappingEntity[];
    dropdownInitialValue?: MappingEntity;
    entity: MappingEntity;
    isSubmitting?: boolean;
    onCancelClick: () => void;
    onConfirmClick: (id: string, srId: string) => void;
    type: 'mapping' | 'remapping';
};

export const CardMapping = (props: Props) => {
    const entityAttributes = useMappingEntityAttributes(props.entity, '-');

    const [mappingEntity, setMappingEntity] = React.useState(props.dropdownInitialValue);
    const mappingEntityOptions = useMappingEntityOptions(props.dropdownEntities, mappingEntity);
    const mappingEntityAttributes = useMappingEntityAttributes(mappingEntity, '-');

    const handleSubmit = React.useCallback(() => {
        if (mappingEntity) {
            props.onConfirmClick(
                props.entity.source === SourceId.SR ? mappingEntity.id : props.entity.id,
                props.entity.source === SourceId.SR ? props.entity.id : mappingEntity.id,
            );
        }
    }, [mappingEntity, props]);

    return (
        <Card>
            {props.type === 'mapping' && (
                <p className="card-title">
                    Map to existing <span className="card-title-type">{props.entity.type}</span>
                </p>
            )}
            {props.type === 'remapping' && (
                <p className="card-title">
                    Remap a <span className="card-title-type">{props.entity.type}</span>
                </p>
            )}
            <div className="card-content">
                <AttributesCard variant={props.type}>
                    <p className="attributes-card-title">
                        {props.type === 'remapping' && `${props.entity.type} to be remapped`}
                        {props.type === 'mapping' && `Unmapped ${props.entity.type}`}
                    </p>
                    <div className="attributes-card-entity-container">
                        <div className="attributes-card-entity">{props.entity.name}</div>
                    </div>
                    <div className="attributes-card-attributes">
                        {Object.entries(entityAttributes).map(([key, value]) => (
                            <React.Fragment key={key}>
                                <span className="attributes-card-label">{key}</span>
                                <span>{value}</span>
                            </React.Fragment>
                        ))}
                    </div>
                </AttributesCard>
                <AttributesCard variant={mappingEntity ? 'mapping-selected' : 'mapping-selecting'}>
                    <p className="attributes-card-title">Map to existing {props.entity.type}</p>
                    <div className="attributes-card-entity-container">
                        <Dropdown
                            minOptionsToSearch={5}
                            onChange={setMappingEntity}
                            options={mappingEntityOptions}
                            placeholderName={'-- Select --'}
                            customStyles={DropdownStyles}
                            searchable
                        />
                    </div>
                    <div className="attributes-card-attributes">
                        {Object.entries(mappingEntityAttributes).map(([key, value]) => (
                            <React.Fragment key={key}>
                                <span className="attributes-card-label">{key}</span>
                                <span>{value}</span>
                            </React.Fragment>
                        ))}
                    </div>
                </AttributesCard>
            </div>
            <div className="card-actions">
                <Button noBorder onClick={props.onCancelClick}>
                    Cancel
                </Button>
                <Button
                    disabled={!mappingEntity || props.isSubmitting}
                    onClick={handleSubmit}
                    type="submit"
                >
                    {props.isSubmitting ? `${props.type}...` : `Confirm ${props.type}`}
                </Button>
            </div>
        </Card>
    );
};
