import { SourceId } from 'types';

import { GetGameQueryParams } from '../types';
export const LeagueKeys = {
    liveFlagCategories: (league: string, source: string) => {
        return [league, 'live-flag-categories', source];
    },
};

export const GameKeys = {
    game: (league: string, id: string, source: SourceId, qp: GetGameQueryParams) => [
        league,
        'game',
        id,
        qp.status,
        source,
        qp.context,
        qp.statName,
    ],
    gameShort(league: string, id: string, status?: string) {
        const keys = [league, 'game', id];

        if (status) {
            keys.push(status);
        }

        return keys;
    },
};
