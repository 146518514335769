import dayjs from 'dayjs';
import * as React from 'react';
import { ChangeEvent } from 'react';

import { DiscrepancyType } from 'api/types/bff';
import { DiscrepancyIgnoreReason, DiscrepancyStatus } from 'api/types/flags';
import { useCurrentSeason } from 'app/useCurrentSeason';
import Dialog from 'components/dialog';
import Dropdown from 'components/dropdown';
import Textarea from 'components/primitives/textarea';

import { sourcesDetails } from '../../../../constants/sources-details';
import useUpdateDiscrepancyState from '../../hooks/useUpdateDiscrepancyState';
import ignoreDropdownOptions from '../ignore-dropdown-options';

import { Content, DiscrepancyValuesGrid, IgnoreReason } from './styles';
import SuccessfullyIgnoredModal from './successfully-ignored-modal';

type Props = {
    open: boolean;
    onClose: () => void;
    discrepancy: DiscrepancyType;
};

const IgnoreDialog = ({ open, onClose, discrepancy }: Props) => {
    const { league } = useCurrentSeason();

    const [ignoreOptions, setIgnoreOptions] = React.useState(ignoreDropdownOptions);
    const [ignoreReason, setIgnoreReason] = React.useState<string>('');
    const discrepancyStateMutation = useUpdateDiscrepancyState(league);
    const [successfullyIgnored, setSuccessfullyIgnored] = React.useState(false);

    const handleChangeIgnoreReason = React.useCallback((option: DiscrepancyIgnoreReason) => {
        setIgnoreOptions((prev) =>
            prev.map((prevOpt) => {
                return {
                    ...prevOpt,
                    selected: prevOpt.value === option,
                };
            }),
        );
    }, []);

    const handleIgnoreReasonChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setIgnoreReason(event.target.value);
    };

    const handleConfirm = React.useCallback(() => {
        const ignoreOptionSelected = ignoreOptions.find((ignoreOption) => ignoreOption.selected);

        discrepancyStateMutation.mutate({
            ...discrepancy,
            status: DiscrepancyStatus.IGNORED,
            ...(ignoreReason ? { ignoreComment: ignoreReason } : {}),
            ...(ignoreOptionSelected ? { ignoreReason: ignoreOptionSelected.value } : {}),
        });
        setSuccessfullyIgnored(true);
    }, [discrepancy, discrepancyStateMutation, ignoreOptions, ignoreReason]);

    const handleCancel = React.useCallback(() => {
        onClose();
    }, [onClose]);

    React.useEffect(() => {
        if (!open) {
            setIgnoreOptions((prev) => prev.map((prevOpt) => ({ ...prevOpt, selected: false })));
            setIgnoreReason('');
            setSuccessfullyIgnored(false);
        }
    }, [open]);

    React.useEffect(() => {
        setIgnoreOptions((prev) =>
            prev.map((prevOpt) => {
                return {
                    ...prevOpt,
                    selected: prevOpt.value === discrepancy.ignore?.reason,
                };
            }),
        );
        setIgnoreReason(discrepancy.ignore?.comment ?? '');
    }, [discrepancy, open]);

    const displayIgnoreTextBox = ignoreOptions.some(
        (ignoreOption) => ignoreOption.selected === true,
    );

    if (open && successfullyIgnored) {
        return <SuccessfullyIgnoredModal open={open} onClose={onClose} />;
    }

    return (
        <Dialog
            open={open}
            onBackDropClick={onClose}
            title="Ignore"
            confirmButtonLabel="Confirm Ignore"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
        >
            <Content>
                <div className="context">
                    {discrepancy.contextGame && (
                        <>
                            <div className="title">Game</div>
                            <div className="value">
                                {dayjs(discrepancy.contextGame.scheduledDate).format('ddd, M/D')}
                                &nbsp;
                                {discrepancy.contextGame?.homeTeam?.name} @{' '}
                                {discrepancy.contextGame?.awayTeam?.name}
                            </div>
                        </>
                    )}
                    {discrepancy.contextTeam && (
                        <>
                            <div className="title">Team</div>
                            <div className="value">{discrepancy.contextTeam.name}</div>
                        </>
                    )}
                    {discrepancy.contextPlayer && (
                        <>
                            <div className="title">Player</div>
                            <div className="value">{discrepancy.contextPlayer.name}</div>
                        </>
                    )}
                    <div className="title">Name</div>
                    <div className="value">{discrepancy.name}</div>
                </div>
                <DiscrepancyValuesGrid>
                    <div className="row">
                        <div className="source --header">Source</div>
                        <div className="value --header">Value</div>
                    </div>
                    {discrepancy.discrepancySources.map((discrepancySource) => (
                        <div key={`ds-${discrepancySource.sourceId}`} className="row">
                            <div className="source">
                                {sourcesDetails[discrepancySource.sourceId].label}
                            </div>
                            <div className="value">{discrepancySource.value}</div>
                        </div>
                    ))}
                </DiscrepancyValuesGrid>
                <IgnoreReason>
                    <div className="dropdown-container">
                        <Dropdown
                            options={ignoreOptions}
                            onChange={handleChangeIgnoreReason}
                            label="Reason to Ignore (Optional)"
                        />
                    </div>
                    {displayIgnoreTextBox && (
                        <div className="reason">
                            <span className="title">Please write a reason</span>
                            <Textarea
                                onBlur={handleIgnoreReasonChange}
                                defaultValue={ignoreReason}
                            />
                        </div>
                    )}
                </IgnoreReason>
            </Content>
        </Dialog>
    );
};

export default IgnoreDialog;
