import { useQuery } from 'react-query';

import API from 'api';
import client from 'api/client';
import { TeamStatus, TeamsResponse } from 'api/types/bff';
import { LeagueType, SourceId } from 'types';

import { LeagueKeys } from './queries';

const getTeams = async (
    league: string,
    seasonYear: string,
    seasonType: string,
    source: SourceId,
    sortBy: string,
    status: TeamStatus,
    division: string,
): Promise<TeamsResponse> => {
    const searchParams = new URLSearchParams({
        seasonYear,
        seasonType,
        source,
        sortBy,
        status,
        ...(division === 'all' ? {} : { division }),
    });

    const response = await client.get<TeamsResponse>(
        `${API.BFF_API}/${league}/teams?${searchParams}`,
    );

    return response?.data;
};

export default function useTeams(
    league: LeagueType,
    seasonYear: string,
    seasonType: string,
    source: SourceId,
    sortBy: string,
    status = TeamStatus.All,
    division = 'all',
) {
    return useQuery(
        LeagueKeys.teams(league, seasonYear, seasonType, source, sortBy, status, division),
        () => getTeams(league, seasonYear, seasonType, source, sortBy, status, division),
        {
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );
}
