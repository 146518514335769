import { bffProcessor } from './bff-processor';
import { liveFlagsProcessor } from './live-flags-processor';
import { systemEventsProcessor } from './system-events-processor';
import { MessageProcessor } from './types';

export const messageProcessorsRegistry = new Map<string, MessageProcessor>();

messageProcessorsRegistry.set(`system-events`, systemEventsProcessor);
messageProcessorsRegistry.set(`live-flags`, liveFlagsProcessor);
messageProcessorsRegistry.set(`bff`, bffProcessor);
